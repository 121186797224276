import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Row, Col, Card, CardBody, Input, Label } from 'reactstrap';
import { Icon } from '@iconify/react';
import { Link } from "react-router-dom";
import userImage2 from "../../assets/images/user/img-02.jpg";
import EmployeeModel from "../../model/employeeModel";
import { useTranslation } from "react-i18next";

import { getUser } from "../../api/apiAccount";

import {
    eyesightList, bloodTypeList,
    preferredHandList, visaTypeList, certificateOfEligibilityList,
    educationLevelList, graduatestatusList, positionList, qualificationList,
    favoriteSubjectList, personalityList, strongPointList, weakpointList
} from "../../common/dropdownList/dropdownData";
const ModalUserInfo = ({ countryData, modalState, toggleModal, username }) => {
    const { t } = useTranslation("translation");
    let [employeeModel, setEmployeeModel] = useState(new EmployeeModel());

    useEffect(() => {
        getUser(username).then((res) => {
            setEmployeeModel(new EmployeeModel(res));
        });
    }, [username]);


    const getmainCate = (childId) => {
        let mainCate = '';
        let subCate = '';
        countryData.category.map((item, key) => {
            if (item.children) {
                item.children.map((child, key) => {

                    if (child.children) {
                        child.children.map((subchild, key) => {
                            if (subchild.id == childId) {
                                mainCate = item.nameVi;
                                subCate = child.nameVi;
                                return {
                                    mainCate: mainCate,
                                    subCate: subCate
                                };
                            }
                        })
                    }
                });
            }
        });
        return {
            mainCate: mainCate,
            subCate: subCate
        };
    }

    return (
        <React.Fragment>
            <div
                className="modal fade"
                id="deleteModal"
                tabIndex="-1"
                aria-labelledby="deleteModal"
                aria-hidden="true"
            >
                <Modal isOpen={modalState} toggle={() => toggleModal()} role="dialog" centered size="xl"
                    backdrop="static"
                    scrollable={true}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title mt-0" id="myModalLabel">
                                Chi tiết ứng viên
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => toggleModal()}
                            ></button>
                        </div>
                        <ModalBody>
                            <div>
                                <Row className='mt-5'>
                                    <Col lg={4} className='text-center'>
                                        <img
                                            src={employeeModel.avatar ? employeeModel.avatar : userImage2}
                                            alt=""
                                            className="avatar-lg rounded-circle"
                                        />
                                        <h6 className="fs-18 mb-0 mt-4">{employeeModel.fullName}</h6>
                                        <p className="text-muted mb-4">{employeeModel.otherName}</p>
                                    </Col>
                                    <Col lg={8}>
                                        <h6 className="fs-17 fw-semibold mb-3">{t("Mô tả bản thân")}</h6>
                                        <p className="text-muted mb-2">
                                            {employeeModel.about}
                                        </p>
                                    </Col>
                                </Row>

                                <div className="mt-4">
                                    <h5 className="fs-17 fw-semibold mb-3">{t("createcv.tinhtrangsuckhoe")}</h5>
                                    <Row>
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label
                                                    htmlFor="height"
                                                    className="form-label"
                                                >
                                                    {t("createcv.chieucao")} : {employeeModel.employeeInfoData.height || 0}
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label
                                                    htmlFor="weight"
                                                    className="form-label"
                                                >
                                                    {t("createcv.cannang")} : {employeeModel.employeeInfoData.weight || 0}
                                                </Label>

                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label
                                                    htmlFor="weight"
                                                    className="form-label"
                                                >
                                                    {t("createcv.thiluc")} : {eyesightList.find(item => item.value === employeeModel.employeeInfoData.eyesight)?.label || ''}
                                                </Label>

                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label
                                                    htmlFor="weight"
                                                    className="form-label"
                                                >
                                                    {t("createcv.nhommau")} : {bloodTypeList.find(item => item.value == employeeModel.employeeInfoData.bloodType)?.label || ''}
                                                </Label>

                                            </div>
                                        </Col>
                                        <Col lg={2}>
                                            <div className="mb-3">
                                                <Label
                                                    htmlFor="weight"
                                                    className="form-label"
                                                >
                                                    {t("createcv.taythuan")} : {preferredHandList.find(item => item.value == employeeModel.employeeInfoData.handedness)?.label || ''}
                                                </Label>

                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                                <div className="mt-4">
                                    <h5 className="fs-17 fw-semibold mb-3">{t("Lịch sử lưu trú tại nước ngoài")}</h5>
                                    {employeeModel.employeeInfoData.visaHistories && employeeModel.employeeInfoData.visaHistories.length > 0 &&
                                        <Row>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("Nước đã xin Visa")}
                                                    </Label>

                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("Loại visa")}
                                                    </Label>

                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("Ngày bắt đầu")}
                                                    </Label>

                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("visaExpDate")}
                                                    </Label>

                                                </div>
                                            </Col>

                                        </Row>
                                    }
                                    {employeeModel.employeeInfoData.visaHistories && employeeModel.employeeInfoData.visaHistories.map((item, key) => (
                                        <Row key={key}>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {countryData.country.find(country => country.id === item.counntryID)?.name || ''}
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("Loại visa")} : {visaTypeList.find(visa => visa.value == item.visaId)?.label || ''}
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {item.fromDate}
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {item.toDate}
                                                    </Label>
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                                    )}
                                    {!employeeModel.employeeInfoData.visaHistories || employeeModel.employeeInfoData.visaHistories.length == 0 && (
                                        <p>{t(certificateOfEligibilityList[0].label || '')}</p>
                                    )}
                                </div>
                                <div className="mt-4">
                                    <h5 className="fs-17 fw-semibold mb-3">{t("Qúa trình học tập")}</h5>

                                    {employeeModel.employeeInfoData.userEducations && employeeModel.employeeInfoData.userEducations.map((item, key) => (
                                        <Row key={key}>
                                            <Col lg={2}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.nhaphoc")}
                                                    </Label>
                                                    <div>{item.enrollmentMonth}</div>
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.totnghiep")}
                                                    </Label>
                                                    <div>{item.graduationMonth}</div>
                                                </div>
                                            </Col>
                                            <Col lg={8}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.tentruong")}
                                                    </Label>
                                                    <div>{item.detail}</div>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.nganhhoc")}
                                                    </Label>
                                                    <div>{item.major}</div>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.loaitruong")}
                                                    </Label>
                                                    <div>{educationLevelList.find(l => l.value == item.typeSchool)?.label || ''}</div>
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.tinhtrangtotnghiep")}
                                                    </Label>
                                                    <div>{graduatestatusList.find(l => l.value == item.graduateStatus)?.label || ''}</div>
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                                    )}
                                    {!employeeModel.employeeInfoData.userEducations || employeeModel.employeeInfoData.userEducations.length == 0 && (
                                        <p>{t("No data")}</p>
                                    )}
                                </div>
                                <div className="mt-4">
                                    <h5 className="fs-17 fw-semibold mb-3">{t("createcv.quatrinhlamviec")}</h5>

                                    {employeeModel.employeeInfoData.userWorkExps && employeeModel.employeeInfoData.userWorkExps.map((item, key) => (
                                        <Row key={key}>
                                            <Col lg={2}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.vaolam")}
                                                    </Label>
                                                    <div>{item.startMonth}</div>
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.nghiviec")}
                                                    </Label>
                                                    <div>{item.endMonth}</div>
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.tencongty")}
                                                    </Label>
                                                    <div>{item.company}</div>
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.chucvu")}
                                                    </Label>
                                                    <div>{positionList.find(i => i.value == item.position)?.label || ''}</div>
                                                </div>
                                            </Col>
                                            <Col lg={12}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.motacongviec")}
                                                    </Label>
                                                    <div>{item.jobDesc}</div>
                                                </div>
                                            </Col>

                                        </Row>
                                    )
                                    )}
                                    {!employeeModel.employeeInfoData.userWorkExps || employeeModel.employeeInfoData.userWorkExps.length == 0 && (
                                        <p>{t("No data")}</p>
                                    )}
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label htmlFor="qualifications" className="form-label">
                                                    {t("createcv.bangcapchungchi")}
                                                </Label>
                                                <div>
                                                    {qualificationList.find(item => item.value == employeeModel.employeeInfoData.qualifications && item.value != 0)?.label || t("visa.khongco")}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    {t("createcv.tiengnhat")}
                                                </Label>
                                                <div>
                                                    {countryData.language.find(item => item.id == employeeModel.employeeInfoData.japaneseLevel)?.levelName || t("visa.khongco")}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    {t("createcv.tienganh")}
                                                </Label>
                                                <div>
                                                    {countryData.language.find(item => item.id == employeeModel.employeeInfoData.englishLevel)?.levelName || t("visa.khongco")}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    {t("createcv.tiengkhac")}
                                                </Label>
                                                <div>
                                                    {countryData.language.find(item => item.id == employeeModel.employeeInfoData.otherLevel)?.levelName || t("visa.khongco")}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col lg={3}>

                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    {t("createcv.monhocsotruong")}
                                                </Label>
                                                <div>
                                                    {favoriteSubjectList.find(i => i.value === employeeModel.employeeInfoData.favoriteSubjects)?.label || t("visa.khongco")}
                                                </div>

                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    {t("personality")}
                                                </Label>
                                                <div>

                                                    {personalityList.find(item => item.value == employeeModel.employeeInfoData.personality && item.value != 0)?.label || t("visa.khongco")}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    {t("createcv.sotruong")}
                                                </Label>
                                                <div>
                                                    {strongPointList.find(item => item.value == employeeModel.employeeInfoData.strongPoints && item.value != 0)?.label || t("visa.khongco")}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label className="form-label">
                                                    {t("createcv.sodoan")}
                                                </Label>
                                                <div>
                                                    {weakpointList.find(item => item.value == employeeModel.employeeInfoData.weakPoints && item.value != 0)?.label || t("visa.khongco")}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="mt-4">
                                    <h5 className="fs-17 fw-semibold mb-3">{t("createcv.kynangnganhnghe")}</h5>
                                    {employeeModel.employeeInfoData.skills && employeeModel.employeeInfoData.skills.length > 0 &&
                                        <Row>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.nganhnghe")}
                                                    </Label>

                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.chuyenmon")}
                                                    </Label>

                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="weight"
                                                        className="form-label"
                                                    >
                                                        {t("createcv.kynang")}
                                                    </Label>

                                                </div>
                                            </Col>


                                        </Row>
                                    }
                                    {employeeModel.employeeInfoData.skills && employeeModel.employeeInfoData.skills.map((item, key) => (
                                        <Row key={key}>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    {getmainCate(item.categoryId).mainCate}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div className="mb-3">

                                                    {getmainCate(item.categoryId).subCate}
                                                </div>
                                            </Col>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    {item.category.nameVi}
                                                </div>
                                            </Col>

                                        </Row>
                                    )
                                    )}
                                    {!employeeModel.employeeInfoData.skills || employeeModel.employeeInfoData.skills.length == 0 && (
                                        <p>{t("visa.khongco")}</p>
                                    )}
                                </div>

                            </div>
                        </ModalBody>
                    </div>
                </Modal>
            </div>
        </React.Fragment>
    )
};

export default ModalUserInfo;