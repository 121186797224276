import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { MasterContext } from "../../../context/MasterContext";
import JobListing from "./JobListing";
import Section from "./Section";
import { getStorage } from "../../../common/constants/Services";
import { STORAGE_KEYS } from "../../../common/constants/Storage";

const ManageJobs = () => {
  const { t } = useTranslation("translation");
  const masterData = React.useContext(MasterContext);
  document.title = t("managejob.danhsachcongviec");

  let [countryId, setCountryId] = useState(1);
  let [countryList, setCountryList] = useState([]);
  let [countryListAll, setCountryListAll] = useState();
  let [nationalityList, setNationalityList] = useState([]);
  let [cityId, setCityId] = useState(1);
  let [cityList, setCityList] = useState();
  let [cityListAll, setCityListAll] = useState();
  let [countryFilterList, setCountryFilterList] = useState();
  let [nationality, setNationality] = useState('');
  let [industry, setIndustry] = useState('');
  let [industryList, setIndustryList] = useState();
  let [industryFilterList, setIndustryFilterList] = useState();
  let [field, setField] = useState('');
  let [fieldsList, setFieldsList] = useState();
  let [visaTypeList, setVisaTypeList] = useState();
  let [foreignLanguagesList, setForeignLanguagesList] = useState();
  let [foreignLanguagesList2, setForeignLanguagesList2] = useState();
  let [foreignLanguagesList3, setForeignLanguagesList3] = useState();
  let [foreignLanguage, setForeignLanguage] = useState('');
  let [foreignLanguageLevel, setforeignLanguageLevel] = useState('');
  let [foreignLanguage2, setForeignLanguage2] = useState('');
  let [foreignLanguageLevel2, setforeignLanguageLevel2] = useState('');
  let [foreignLanguage3, setForeignLanguage3] = useState('');
  let [foreignLanguageLevel3, setforeignLanguageLevel3] = useState('');
  let [foreignLanguageLevelList, setForeignLanguageLevelList] = useState();
  let [foreignLanguageLevelList2, setForeignLanguageLevelList2] = useState();
  let [foreignLanguageLevelList3, setForeignLanguageLevelList3] = useState();
  let [foreignLanguageLevelAll, setForeignLanguageLevelAll] = useState();
  let [foreignLanguageAll, setForeignLanguageAll] = useState();
  let [currency, setCurrency] = useState('VND');
  let [currencyList, setCurrencyList] = useState();


  useEffect(() => {
    if (masterData.country) {
      const countryArr = [];
      const nationalityArr = [{ value: 0, label: t('Bất kỳ') }];
      const countryFilterArr = [{ value: 0, label: t(t("managejob.quocgia")) }];
      const cityArr = [];
      const cityListAllArr = [];
      const currencyArr = [];
      const visaAllArr = [{ value: 0, label: t("kinhnghiem.khongyeucau") }];
      masterData.country.map((item, key) => {
        if (key === 0) {
          setCountryId({ value: item.id, label: item.name, currency: item.currency, visas: item.visas });
          setNationality({ value: item.id, label: item.name });
          setCurrency({ value: item.currency, label: item.currency });
          let cities = item.cities.filter(c => c.countryID === item.id);
          if (cities.length > 0) {
            cities.map((itemcity, keycity) => {
              if (keycity === 0) {
                setCityId({ value: itemcity.id, label: itemcity.name });
              }
              cityArr.push({ value: itemcity.id, label: itemcity.name });
            })
            setCityList(cityArr);
          }
        }
        if (item.currency) {
          currencyArr.push({ value: item.currency, label: item.currency });
        }
        if (item.visas.length) {
          item.visas.map((itemvisa, keyvisa) => {
            visaAllArr.push({ value: itemvisa.id, label: itemvisa.name, countryId: itemvisa.countryId });
          })
        }
        countryArr.push({ value: item.id, label: item.name, currency: item.currency, visas: item.visas });
        nationalityArr.push({ value: item.id, label: item.name });
        countryFilterArr.push({ value: item.id, label: item.name });
        cityListAllArr.push(item.cities);
      })
      setVisaTypeList(visaAllArr);
      setCurrencyList(currencyArr);
      setCountryListAll(masterData.country);
      setCountryList(countryArr);
      setNationalityList(nationalityArr);
      setCountryFilterList(countryFilterArr);
      setCityListAll(cityListAllArr);
    };
    if (masterData.category) {
      const industryListArr = [];
      const industryFilterArr = [{ value: 0, label: t('Ngành nghề') }];
      const fieldArr = [];
      masterData.category.map((item, key) => {
        if (key === 0) {
          if (getStorage(STORAGE_KEYS.LANGUAGE) == 'vi' || !getStorage(STORAGE_KEYS.LANGUAGE)) {
            setIndustry({ value: item.id, label: item.nameVi });
          } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'jp') {
            setIndustry({ value: item.id, label: item.nameJp });
          } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'kr') {
            setIndustry({ value: item.id, label: item.nameKr });
          } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'en') {
            setIndustry({ value: item.id, label: item.nameEn });
          } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'gr') {
            setIndustry({ value: item.id, label: item.nameGr });
          }
          item.children.map((item2, key2) => {
            if (key2 === 0) {
              if (getStorage(STORAGE_KEYS.LANGUAGE) == 'vi' || !getStorage(STORAGE_KEYS.LANGUAGE)) {
                setField({ value: item2.id, label: item2.nameVi });
              } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'jp') {
                setField({ value: item2.id, label: item2.nameJp });
              } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'kr') {
                setField({ value: item2.id, label: item2.nameKr });
              } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'en') {
                setField({ value: item2.id, label: item2.nameEn });
              } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'gr') {
                setField({ value: item2.id, label: item2.nameGr });
              }
            }
            if (getStorage(STORAGE_KEYS.LANGUAGE) == 'vi' || !getStorage(STORAGE_KEYS.LANGUAGE)) {
              fieldArr.push({ value: item2.id, label: item2.nameVi });
            } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'jp') {
              fieldArr.push({ value: item2.id, label: item2.nameJp });
            } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'kr') {
              fieldArr.push({ value: item2.id, label: item2.nameKr });
            } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'en') {
              fieldArr.push({ value: item2.id, label: item2.nameEn });
            } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'gr') {
              fieldArr.push({ value: item2.id, label: item2.nameGr });
            }
          })
        }
        
        if (getStorage(STORAGE_KEYS.LANGUAGE) == 'vi' || !getStorage(STORAGE_KEYS.LANGUAGE)) {
          industryListArr.push({ value: item.id, label: item.nameVi, children: item.children });
          industryFilterArr.push({ value: item.id, label: item.nameVi });
        } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'jp') {
          industryListArr.push({ value: item.id, label: item.nameJp, children: item.children });
          industryFilterArr.push({ value: item.id, label: item.nameJp });
        } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'kr') {
          industryListArr.push({ value: item.id, label: item.nameKr, children: item.children });
          industryFilterArr.push({ value: item.id, label: item.nameKr });
        } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'en') {
          industryListArr.push({ value: item.id, label: item.nameEn, children: item.children });
          industryFilterArr.push({ value: item.id, label: item.nameEn });
        } if (getStorage(STORAGE_KEYS.LANGUAGE) == 'gr') {
          industryListArr.push({ value: item.id, label: item.nameGr, children: item.children });
          industryFilterArr.push({ value: item.id, label: item.nameGr });
        }
        
      });
      setIndustryList(industryListArr);
      setIndustryFilterList(industryFilterArr);
      setFieldsList(fieldArr);
    };
    if (masterData.language) {
      let languageData = masterData.language.filter((value, index, self) => self.findIndex((l) => l.countryId === value.countryId) === index);

      const languageListArr = [{ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") }];
      languageData.map((item, key) => {
        let languageDefine = '';
        if (item.levelCode === "VN") {
          languageDefine = t('Tiếng Việt');
        }
        if (item.levelCode === "JP") {
          languageDefine = t('Tiếng Nhật');
        }
        if (item.levelCode === "KR") {
          languageDefine = t('Tiếng Hàn');
        }
        if (item.levelCode === "GER") {
          languageDefine = t('Tiếng Đức');
        }
        if (item.levelCode === "EN") {
          languageDefine = t('Tiếng Anh');
        }
        if (item.levelCode === "CN") {
          languageDefine = t('Tiếng Trung');
        }
        if (item.levelCode === "SA") {
          languageDefine = t('Tiếng Ả Rập');
        }
        if (item.levelCode === "RU") {
          languageDefine = t('Tiếng Nga');
        }
        languageListArr.push({ value: item.id, label: languageDefine, levelCode: item.levelCode, levelName: item.levelName });
        if (key === 0) {
          setForeignLanguage({ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") });
          setForeignLanguage2({ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") });
          setForeignLanguage3({ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") });
        }
      });
      setForeignLanguagesList(languageListArr);
      setForeignLanguagesList2(languageListArr);
      setForeignLanguagesList3(languageListArr);

      const languageLevelListArr = [{ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") }];
      const languageLevelAllListArr = [{ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") }];
      masterData.language.map((item, key) => {
        if (key === 0) {
          setforeignLanguageLevel({ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") });
          setforeignLanguageLevel2({ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") });
          setforeignLanguageLevel3({ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") });
        }
        if (item.levelCode === "JP") {
          languageLevelListArr.push({ value: item.id, label: item.levelName, levelCode: item.levelCode });
        }
        languageLevelAllListArr.push({ value: item.id, label: item.levelName, levelCode: item.levelCode });
      });
      setForeignLanguageLevelList(languageLevelListArr);
      setForeignLanguageLevelList2(languageLevelListArr);
      setForeignLanguageLevelList3(languageLevelListArr);
      setForeignLanguageLevelAll(languageLevelAllListArr);
      setForeignLanguageAll(masterData.language);
    }
  }, [getStorage(STORAGE_KEYS.LANGUAGE)])

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          {foreignLanguageAll && <JobListing countryId={countryId} countryList={countryList} countryListAll={countryListAll}
            nationalityList={nationalityList} cityId={cityId} cityList={cityList} cityListAll={cityListAll} countryFilterList={countryFilterList}
            nationality={nationality} industry={industry} industryList={industryList} industryFilterList={industryFilterList} field={field}
            fieldsList={fieldsList} visaTypeList={visaTypeList} foreignLanguagesList={foreignLanguagesList} foreignLanguagesList2={foreignLanguagesList2}
            foreignLanguagesList3={foreignLanguagesList3} foreignLanguage={foreignLanguage} foreignLanguageLevel={foreignLanguageLevel}
            foreignLanguage2={foreignLanguage2} foreignLanguageLevel2={foreignLanguageLevel2} foreignLanguage3={foreignLanguage3}
            foreignLanguageLevel3={foreignLanguageLevel3} foreignLanguageLevelList={foreignLanguageLevelList} foreignLanguageLevelList2={foreignLanguageLevelList2}
            foreignLanguageLevelList3={foreignLanguageLevelList3} foreignLanguageLevelAll={foreignLanguageLevelAll} foreignLanguageAll={foreignLanguageAll}
            currency={currency} currencyList={currencyList}
          />}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ManageJobs;
