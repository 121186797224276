import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Modal, ModalBody, ModalFooter, Label, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import Moment from 'moment';
import Select from 'react-select';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, LineShareButton } from "react-share";
import { Icon } from '@iconify/react';
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CurrencyInput from 'react-currency-input-field';
//Import Images
import JobDetailImage from "../../../assets/images/job-detail.jpg";
import { Link } from "react-router-dom";
import { numberFormat } from "../../../common/checkValidate/formatMoney";
import { updateJob, getJobsById } from "../../../api/apiJob";
import { getAccountInfo, getMyFile } from "../../../api/apiAccount";
import { STORAGE_KEYS } from "../../../common/constants/Storage";
import { getStorage } from "../../../common/constants/Services";
import ApplyJobPopup from "../../ExtraPages/Components/ApplyJobPopup";
import AlertsSuccess from "../../../pages/ExtraPages/Components/AlertsSuccess";
import AlertsError from "../../../pages/ExtraPages/Components/AlertsError";
import AlertCheckFile from "../../../common/alerts/AlertCheckFile";
import { MESSAGE_VALIDATE } from "../../../common/constants/Messages";
import { checkFileImageUpload } from "../../../common/checkValidate/checkFileImageUpload";
import {
  jobTypeList, workingTypeList, candidateTypeList,
  reviewSalaryList, experienceRequiredList
} from "../../../common/dropdownList/dropdownData";
import ImageDefault from "../../../assets/images/featured-job/img-default.webp";
import JobVacancyPost from "./JobVacancyPost";
import PlaceholderLoaddingJobDescription from "../../../common/placeholder/PlaceholderLoaddingJobDescription";

const JobDetailsDescription = (props) => {

  const { t } = useTranslation("translation");

  const genderList = [
    { value: "0", label: t("gioitinh.khonghienthi") },
    { value: "1", label: t("gioitinh.nam") },
    { value: "2", label: t("gioitinh.nu") },
    { value: "3", label: t("gioitinh.namnu") },
  ];

  const positionList = [
    { value: "1", label: t("chucvu.tonggiamdoc") },
    { value: "2", label: t("chucvu.giamdoc") },
    { value: "3", label: t("chucvu.phogiamdoc") },
    { value: "4", label: t("chucvu.hdqt") },
    { value: "5", label: t("chucvu.truongphong") },
    { value: "6", label: t("chucvu.truongnhom") },
    { value: "7", label: t("chucvu.nhanvien") },
  ];

  const educationLevelList = [
    { value: "0", label: t("capdo.tieuhoc") },
    { value: "1", label: t("capdo.thcs") },
    { value: "2", label: t("capdo.thpt") },
    { value: "3", label: t("capdo.trungcap") },
    { value: "4", label: t("capdo.caodang") },
    { value: "5", label: t("capdo.daihoc") },
    { value: "6", label: t("capdo.caohoc") },
  ];

  //Apply Now Model
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  //modalAdd
  const [modalAdd, setModalAdd] = useState(false);
  const openModalAdd = () => setModalAdd(!modalAdd);

  const token = getStorage(STORAGE_KEYS.ACCESS_TOKEN);
  const role = getStorage(STORAGE_KEYS.ROLE);

  const customReactSelectStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      borderColor: '#eff0f2',
      height: '42.6px',
      fontWeight: 500
    }),
    menuPortal: provided => ({ ...provided, zIndex: 99 }),
  };

  const customSelectValidateStyles = {
    control: (baseStyles) => ({
      ...baseStyles,
      borderColor: 'red',
      height: '42.6px',
      fontWeight: 500
    }),
    menuPortal: provided => ({ ...provided, zIndex: 99 }),
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const routeCompany = "/company/";

  let [email, setEmail] = useState('');
  let [companyImage, setCompanyImage] = useState('');
  let [fullname, setFullName] = useState('');
  let [phone, setPhone] = useState('');
  let [address, setAddress] = useState('');
  let [website, setWebsite] = useState('');
  let [facebook, setFacebook] = useState('');
  let [twitter, setTwitter] = useState('');
  let [instagram, setInstagram] = useState('');
  let [whatsapp, setWhatsapp] = useState('');


  let [banner, setBanner] = useState('');
  let [title, setTitle] = useState('');
  let [jobType, setJobType] = useState('');
  let [workingType, setWorkingType] = useState('');
  let [workingTypeDes, setWorkingTypeDes] = useState('');
  let [position, setPosition] = useState('');
  let [experienceRequired, setExperienceRequired] = useState('');
  let [salaryTo, setSalaryTo] = useState(1000);
  let [currency, setCurrency] = useState('VND');
  let [jobDescription, setJobDescription] = useState('');
  let [jobRequired, setJobRequired] = useState('');

  let [addressCountry, setAddressCountry] = useState('');
  let [workLocationAddress, setWorkLocationAddress] = useState('');
  let [salaryFrom, setSalaryFrom] = useState(500);
  let [currencyUpdate, setCurrencyUpdate] = useState('VND');

  let [isMe, setIsMe] = useState(false);

  let [alertsuccess, setAlertSuccess] = useState(false);
  let [alertdanger, setAlertDanger] = useState(false);
  const openDanger = () => setAlertDanger(!alertdanger);
  let [alertlogin, setAlertLogin] = useState(false);
  const openLogin = () => setAlertLogin(!alertlogin);
  let [checkFile, setCheckFile] = useState(false);

  let date = Moment(new Date()).format("YYYY-MM-DD");

  let [base64file, setBase64File] = useState('');
  let [gender, setGender] = useState('');
  let [genderDes, setGenderDes] = useState('');
  let [ageFrom, setAgeFrom] = useState(18);
  let [ageTo, setAgeTo] = useState(35);
  let [ageDes, setAgeDes] = useState('');
  let [jobTypeUpdate, setJobTypeUpdate] = useState('');
  let [positionUpdate, setPositionUpdate] = useState('');
  let [experience, setExperience] = useState('');
  let [totalWorkerNeeded, setTotalWorkerNeeded] = useState(2);
  let [totalWorkerNeededDes, setTotalWorkerNeededDes] = useState(2);
  let [currencyList, setCurrencyList] = useState();
  let [dateNeeded, setDateNeeded] = useState(date);
  let [dateNeededDes, setDateNeededDes] = useState(date);
  let [editorJobDescription, setEditorJobDescription] = useState(EditorState.createEmpty());
  let [editorJobRequired, setEditorJobRequired] = useState(EditorState.createEmpty());
  let [visaType, setVisaType] = useState();
  let [visaTypeList, setVisaTypeList] = useState();
  let [visaTypeDes, setVisaTypeDes] = useState('');
  let [foreignLanguagesList, setForeignLanguagesList] = useState();
  let [foreignLanguagesList2, setForeignLanguagesList2] = useState();
  let [foreignLanguagesList3, setForeignLanguagesList3] = useState();
  let [educationLevel, setEducationLevel] = useState('');
  let [educationLevelDes, setEducationLevelDes] = useState('');
  let [foreignLanguage, setForeignLanguage] = useState('');
  let [foreignLanguageLevel, setforeignLanguageLevel] = useState('');
  let [isForeignLanguage, setIsForeignLanguage] = useState(false);
  let [foreignLanguage2, setForeignLanguage2] = useState('');
  let [foreignLanguageLevel2, setforeignLanguageLevel2] = useState('');
  let [foreignLanguage3, setForeignLanguage3] = useState('');
  let [foreignLanguageLevel3, setforeignLanguageLevel3] = useState('');
  let [foreignLanguageLevelList, setForeignLanguageLevelList] = useState();
  let [foreignLanguageLevelList2, setForeignLanguageLevelList2] = useState();
  let [foreignLanguageLevelList3, setForeignLanguageLevelList3] = useState();
  let [foreignLanguageLevelAll, setForeignLanguageLevelAll] = useState();
  let [foreignLanguageAll, setForeignLanguageAll] = useState();

  let [candidateType, setCandidateType] = useState('');
  let [candidateTypeDes, setCandidateTypeDes] = useState('');
  let [reviewSalary, setReviewSalary] = useState('');

  let [countryId, setCountryId] = useState(1);
  let [countryList, setCountryList] = useState([]);
  let [countryListAll, setCountryListAll] = useState();
  let [nationalityList, setNationalityList] = useState([]);
  let [cityId, setCityId] = useState(1);
  let [cityList, setCityList] = useState();
  let [cityListAll, setCityListAll] = useState();
  let [nationality, setNationality] = useState('');
  let [nationalityDes, setNationalityDes] = useState('');
  let [industry, setIndustry] = useState('');
  let [industryList, setIndustryList] = useState();
  let [industryDes, setIndustryDes] = useState('');
  let [field, setField] = useState('');
  let [fieldsList, setFieldsList] = useState();
  let [fieldsDes, setFieldsDes] = useState('');

  let [bonus, setBonus] = useState(false);

  let [tcAnTrua, settcAnTrua] = useState(false);
  let [tcNhaO, settcNhaO] = useState(false);
  let [tcDiLai, settcDiLai] = useState(false);
  let [tcNghiViec, settcNghiViec] = useState(false);
  let [tcKhac, settcKhac] = useState(false);
  let [contentKhac, setContentKhac] = useState('');

  let [reviewSalaryDes, setReviewSalaryDes] = useState('');
  let [tcAnTruaDes, settcAnTruaDes] = useState('');
  let [tcNhaODes, settcNhaODes] = useState('');
  let [tcDiLaiDes, settcDiLaiDes] = useState('');
  let [tcNghiViecDes, settcNghiViecDes] = useState('');
  let [contentKhacDes, setContentKhacDes] = useState('');

  let [hscv, setHSCV] = useState(false);
  let [hsbc, setHSBC] = useState(false);
  let [hstc, setHSTC] = useState(false);
  let [hstct, setHSTCT] = useState(false);
  let [hshc, setHSHC] = useState(false);
  let [hsblx, setHSBLX] = useState(false);

  let [hscvDes, setHSCVDes] = useState('');
  let [hsbcDes, setHSBCDes] = useState('');
  let [hstcDes, setHSTCDes] = useState('');
  let [hstctDes, setHSTCTDes] = useState('');
  let [hshcDes, setHSHCDes] = useState('');
  let [hsblxDes, setHSBLXDes] = useState('');

  let [invalid, setInvalid] = useState(false);
  let [urlShare, seturlShare] = useState('');

  let [alertUpdateSuccess, setAlertUpdateSuccess] = useState(false);
  let [alertUpdateDanger, setAlertUpdateDanger] = useState(false);
  const openUpdateDanger = () => setAlertUpdateDanger(!alertUpdateDanger);
  let [alertJobExpired, setAlertJobExpired] = useState(false);
  const openJobExpired = () => setAlertJobExpired(!alertJobExpired);
  let [titleErr, setTitleErr] = useState('');
  let [cityErr, setCityErr] = useState('');
  let [nationalityErr, setNationalityErr] = useState('');
  let [visaTypeErr, setVisaTypeErr] = useState('');
  let [salaryFromErr, setSalaryFromErr] = useState('');
  let [salaryToErr, setSalaryToErr] = useState('');
  let [ageFromErr, setAgeFromErr] = useState('');
  let [ageToErr, setAgeToErr] = useState('');
  let [avatarErr, setAvatarErr] = useState('');

  useEffect(() => {
    if (props.masterList.country) {
      props.data.banner ? setBanner(props.data.banner) : setBanner('');
      props.data.title ? setTitle(props.data.title) : setTitle('');
      if (props.data.nationality.length > 1) {
        let nationalityRes = props.data.nationality.split(',');
        let nationalityData = '';
        nationalityRes.map((item, key) => {
          let data = props.masterList.country.filter(c => c.id == item);
          if (data) {
            data.map((itemData, keyData) => {
              if (key == nationalityRes.length - 1) {
                nationalityData += itemData.name;
              } else {
                nationalityData += itemData.name + ', ';
              }
            });
          }
        })
        setNationalityDes(nationalityData);
      } else {
        if (props.data.nationality == 0) {
          setNationalityDes(t("Bất kỳ"));
        } else {
          let data = props.masterList.country.filter(c => c.id == props.data.nationality);
          if (data) {
            setNationalityDes(data[0].name);
          }
        }
      }

      let visaListDes = [];
      props.masterList.country.map((item, key) => {
        item.visas.map((visaItem, visaKey) => {
          visaListDes.push({ id: visaItem.id, name: visaItem.name });
        })
      });

      if (props.data.visaType.length > 1) {
        let visaRes = props.data.visaType.split(',');
        let visaResData = '';
        visaRes.map((item, key) => {
          let data = visaListDes.filter(c => c.id == item);
          if (data) {
            data.map((itemData, keyData) => {
              if (key == visaRes.length - 1) {
                visaResData += itemData.name;
              } else {
                visaResData += itemData.name + '; ';
              }
            });
          }
        })
        setVisaTypeDes(visaResData);
      } else {
        if (props.data.visaType == 0) {
          setVisaTypeDes(t("kinhnghiem.khongyeucau"));
        } else {
          let data = visaListDes.filter(c => c.id == props.data.visaType);
          if (data) {
            setVisaTypeDes(data[0].name);
          }
        }
      }

      if (props.data.educationLevel == 0) {
        setEducationLevelDes(educationLevelList[0].label);
      } else if (props.data.educationLevel == 1) {
        setEducationLevelDes(educationLevelList[1].label);
      } else if (props.data.educationLevel == 2) {
        setEducationLevelDes(educationLevelList[2].label);
      } else if (props.data.educationLevel == 3) {
        setEducationLevelDes(educationLevelList[3].label);
      } else if (props.data.educationLevel == 4) {
        setEducationLevelDes(educationLevelList[4].label);
      } else if (props.data.educationLevel == 5) {
        setEducationLevelDes(educationLevelList[5].label);
      } else {
        setEducationLevelDes(educationLevelList[6].label);
      }

      props.data.jobType === 1 ? setJobType(jobTypeList[2].label) : (props.data.jobType === 0 ? setJobType(jobTypeList[1].label) : setJobType(jobTypeList[0].label));
      props.data.workingType == 0 ? setWorkingTypeDes(workingTypeList[0].label) : (props.data.workingType == 1 ? setWorkingTypeDes(workingTypeList[1].label) : setWorkingTypeDes(workingTypeList[2].label));

      if (props.data.experienceRequired === 0) {
        setExperienceRequired(experienceRequiredList[0].label);
      } else if (props.data.experienceRequired === 1) {
        setExperienceRequired(experienceRequiredList[1].label);
      } else if (props.data.experienceRequired === 2) {
        setExperienceRequired(experienceRequiredList[2].label);
      } else if (props.data.experienceRequired === 3) {
        setExperienceRequired(experienceRequiredList[3].label);
      } else if (props.data.experienceRequired === 4) {
        setExperienceRequired(experienceRequiredList[4].label);
      } else {
        setExperienceRequired(experienceRequiredList[5].label);
      }

      if (props.data.position === 1) {
        setPosition(positionList[0].label);
      } else if (props.data.position === 2) {
        setPosition(positionList[1].label);
      } else if (props.data.position === 3) {
        setPosition(positionList[2].label);
      } else if (props.data.position === 4) {
        setPosition(positionList[3].label);
      } else if (props.data.position === 5) {
        setPosition(positionList[4].label);
      } else if (props.data.position === 6) {
        setPosition(positionList[5].label);
      } else {
        setPosition(positionList[6].label);
      }

      if (props.data.catId) {
        let industryData = props.masterList.category.filter(i => i.id == props.data.catId);
        if (industryData) {
          setIndustryDes(industryData[0].nameVi);
          industryData[0].children.map((item, key) => {
            if (item.id === props.data.catId2) {
              setFieldsDes(item.nameVi);
            }
          })
        }
      }

      props.data.candidateType == 0 ? setCandidateTypeDes(candidateTypeList[0].label) : setCandidateTypeDes(candidateTypeList[1].label);
      props.data.workLocationAddress ? setWorkLocationAddress(props.data.workLocationAddress) : setWorkLocationAddress('');
      props.data.salaryFrom ? setSalaryFrom(props.data.salaryFrom) : setSalaryFrom(500);
      props.data.salaryTo ? setSalaryTo(props.data.salaryTo) : setSalaryTo(1000);
      props.data.bonus ? setBonus(props.data.bonus) : setBonus(false);
      props.data.reviewSalary == 0 ? setReviewSalaryDes(reviewSalaryList[0].label) : (props.data.reviewSalary == 1 ? setReviewSalaryDes(reviewSalaryList[1].label) : setReviewSalaryDes(reviewSalaryList[2].label));
      props.data.currency ? setCurrency(props.data.currency) : setCurrency('VND');
      props.data.jobDescription ? setJobDescription(props.data.jobDescription) : setJobDescription('');
      props.data.jobRequired ? setJobRequired(props.data.jobRequired) : setJobRequired('');
      props.data.dateNeeded ? setDateNeededDes(props.data.dateNeeded) : setDateNeededDes(date);
      props.data.dateNeeded ? setDateNeeded(props.data.dateNeeded) : setDateNeeded(date);
      props.data.totalWorkerNeeded ? setTotalWorkerNeededDes(props.data.totalWorkerNeeded) : setTotalWorkerNeededDes(2);
      if (props.data.gender == 0) {
        setGenderDes(genderList[0].label);
      } else if (props.data.gender == 1) {
        setGenderDes(genderList[1].label);
      } else if (props.data.gender == 2) {
        setGenderDes(genderList[2].label);
      } else {
        setGenderDes(genderList[3].label);
      }
      props.data.ageFrom ? setAgeFrom(props.data.ageFrom) : setAgeFrom(1);
      props.data.ageTo ? setAgeTo(props.data.ageTo) : setAgeTo(10);
      setAgeDes(numberFormat(props.data.ageFrom) + ' - ' + numberFormat(props.data.ageTo));
      props.data.tcAnTrua ? settcAnTruaDes(t("managejob.antrua")) : settcAnTruaDes('');
      props.data.tcNhaO ? settcNhaODes(t("managejob.nhao")) : settcNhaODes('');
      props.data.tcDiLai ? settcDiLaiDes(t("managejob.dilai")) : settcDiLaiDes('');
      props.data.tcNghiViec ? settcNghiViecDes(t("managejob.nghiviec")) : settcNghiViecDes('');
      props.data.tcKhac ? setContentKhacDes(props.data.tcKhac_Content) : setContentKhacDes('');

      props.data.hscv ? setHSCVDes(t("managejob.cv")) : setHSCVDes('');
      props.data.hsbc ? setHSBCDes(t("managejob.bangcap")) : setHSBCDes('');
      props.data.hstc ? setHSTCDes(t("managejob.chungchi")) : setHSTCDes('');
      props.data.hstct ? setHSTCTDes(t("managejob.thecutru")) : setHSTCTDes('');
      props.data.hshc ? setHSHCDes(t("managejob.hochieu")) : setHSHCDes('');
      props.data.hsblx ? setHSBLXDes(t("managejob.banglaixe")) : setHSBLXDes('');

      props.data.companyName ? setFullName(props.data.companyName) : setFullName('');
      props.data.address ? setAddress(props.data.address) : setAddress('');
      props.data.email ? setEmail(props.data.email) : setEmail('');
      props.data.avatar ? setCompanyImage(props.data.avatar) : setCompanyImage(ImageDefault);
      if (props.data.phone) {
        let phoneData = props.data.phone.split('|');
        phoneData[1] ? setPhone("(" + phoneData[0] + ") " + phoneData[1]) : setPhone('');
      };
      if (props.data.website) {
        let websiteData = props.data.website.split('|');
        websiteData[0] ? setWebsite(websiteData[0]) : setWebsite("");
        websiteData[1] ? setFacebook(websiteData[1]) : setFacebook("");
        websiteData[2] ? setInstagram(websiteData[2]) : setInstagram("");
        websiteData[3] ? setTwitter(websiteData[3]) : setTwitter("");
        websiteData[4] ? setWhatsapp(websiteData[4]) : setWhatsapp("");
      };
    }
  }, [props.masterList, getStorage(STORAGE_KEYS.LANGUAGE)]);

  useEffect(() => {
    seturlShare(window.location.href);
    if (token) {
      getAccountInfo().then((res) => {
        if (res) {
          if (res.candidateData) {
            (res.candidateData.userId == props.data.userId) ? setIsMe(true) : setIsMe(false);
          }
        }
      });
    }
    if (props.masterList.country) {
      const countryArr = [];
      const nationalityArr = [{ value: 0, label: t('Bất kỳ') }];
      const cityArr = [];
      const cityListAllArr = [];
      const currencyArr = [];
      const visaAllArr = [{ value: 0, label: t("kinhnghiem.khongyeucau") }];
      props.masterList.country.map((item, key) => {
        if (key === 0) {
          setCountryId({ value: item.id, label: item.name, currency: item.currency, visas: item.visas });
          setNationality({ value: item.id, label: item.name });
          let cities = item.cities.filter(c => c.countryID === item.id);
          if (cities.length > 0) {
            cities.map((itemcity, keycity) => {
              if (keycity === 0) {
                setCityId({ value: itemcity.id, label: itemcity.name });
              }
              if (itemcity.id == props.data.cityId) {
                setAddressCountry(item.name + '-' + itemcity.name + ',');
              }
              cityArr.push({ value: itemcity.id, label: itemcity.name });
            })
            setCityList(cityArr);
          }
        } else {
          item.cities.map((itemcity, keycity) => {
            if (itemcity.id == props.data.cityId) {
              setAddressCountry(item.name + '-' + itemcity.name + ',');
            }
            cityArr.push({ value: itemcity.id, label: itemcity.name });
          })
        }
        if (item.currency) {
          currencyArr.push({ value: item.currency, label: item.currency });
        }
        if (item.visas.length) {
          item.visas.map((itemvisa, keyvisa) => {
            visaAllArr.push({ value: itemvisa.id, label: itemvisa.name, countryId: itemvisa.countryId });
          })
        }
        countryArr.push({ value: item.id, label: item.name, currency: item.currency, visas: item.visas });
        nationalityArr.push({ value: item.id, label: item.name });
        cityListAllArr.push(item.cities);
      })
      setVisaTypeList(visaAllArr);
      setCurrencyList(currencyArr);
      setCountryListAll(props.masterList.country);
      setCountryList(countryArr);
      setNationalityList(nationalityArr);
      setCityListAll(cityListAllArr);
    };
    if (props.masterList.category) {
      const industryListArr = [];
      const fieldArr = [];
      props.masterList.category.map((item, key) => {
        if (key === 0) {
          setIndustry({ value: item.id, label: item.nameVi });
          item.children.map((item2, key2) => {
            if (key2 === 0) {
              setField({ value: item2.id, label: item2.nameVi });
            }
            fieldArr.push({ value: item2.id, label: item2.nameVi });
          })
        }
        industryListArr.push({ value: item.id, label: item.nameVi, children: item.children });
      });
      setIndustryList(industryListArr);
      setFieldsList(fieldArr);
    };
    if (props.masterList.language) {
      let languageData = props.masterList.language.filter((value, index, self) => self.findIndex((l) => l.countryId === value.countryId) === index);

      const languageListArr = [{ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") }];
      languageData.map((item, key) => {
        let languageDefine = '';
        if (item.levelCode === "VN") {
          languageDefine = t('Tiếng Việt');
        }
        if (item.levelCode === "JP") {
          languageDefine = t('Tiếng Nhật');
        }
        if (item.levelCode === "KR") {
          languageDefine = t('Tiếng Hàn');
        }
        if (item.levelCode === "GER") {
          languageDefine = t('Tiếng Đức');
        }
        if (item.levelCode === "EN") {
          languageDefine = t('Tiếng Anh');
        }
        if (item.levelCode === "CN") {
          languageDefine = t('Tiếng Trung');
        }
        if (item.levelCode === "SA") {
          languageDefine = t('Tiếng Ả Rập');
        }
        if (item.levelCode === "RU") {
          languageDefine = t('Tiếng Nga');
        }
        languageListArr.push({ value: item.id, label: languageDefine, levelCode: item.levelCode, levelName: item.levelName });
        if (key === 0) {
          setForeignLanguage({ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") });
          setForeignLanguage2({ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") });
          setForeignLanguage3({ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") });
        }
      });
      setForeignLanguagesList(languageListArr);
      setForeignLanguagesList2(languageListArr);
      setForeignLanguagesList3(languageListArr);

      const languageLevelListArr = [];
      const languageLevelAllListArr = [];
      props.masterList.language.map((item, key) => {
        if (key === 0) {
          setforeignLanguageLevel({ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") });
          setforeignLanguageLevel2({ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") });
          setforeignLanguageLevel3({ value: 0, label: t("kinhnghiem.khongyeucau"), levelCode: '', levelName: t("kinhnghiem.khongyeucau") });
        }
        if (item.levelCode === "JP") {
          languageLevelListArr.push({ value: item.id, label: item.levelName, levelCode: item.levelCode });
        }
        languageLevelAllListArr.push({ value: item.id, label: item.levelName, levelCode: item.levelCode });
      });
      setForeignLanguageLevelList(languageLevelListArr);
      setForeignLanguageLevelList2(languageLevelListArr);
      setForeignLanguageLevelList3(languageLevelListArr);
      setForeignLanguageLevelAll(languageLevelAllListArr);
      setForeignLanguageAll(props.masterList.language);
    }
  }, [props, getStorage(STORAGE_KEYS.LANGUAGE)]);

  const messageApplyJob = (message) => {
    if (message) {
      setAlertSuccess(true);
      setAlertDanger(false);
      setAlertLogin(false);
    } else {
      setAlertDanger(true);
      setAlertSuccess(false);
      setAlertLogin(false);
    }
  }

  const handleApplyJob = () => {
    if (token && role === 'Employee') {
      if (Moment(date).format("YYYYMMDD") > Moment(dateNeeded).format("YYYYMMDD")) {
        openJobExpired();
      } else {
        getMyFile().then((res) => {
          if (res.length > 0) {
            setCheckFile(false);
            openModal();
          } else {
            setCheckFile(true);
          }
        });
      }
    } else {
      setAlertLogin(true);
    }
  }

  const checkFileApplyJob = (checkfile) => {
    if (checkfile) {
      setCheckFile(false);
    }
  }

  const handleUploadBanner = async event => {
    const bannerLoaded = URL.createObjectURL(event.target.files[0]);
    const base64 = await convertBase64(event.target.files[0]);
    let base64Data = base64.split(',');
    setBase64File(base64Data[1]);
    setBanner(bannerLoaded);
    let fileSize = checkFileImageUpload(event.target.files[0]);
    if (fileSize) {
      setAvatarErr("");
      title && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0
        && Number(salaryFrom) < Number(salaryTo) && Number(ageFrom) < Number(ageTo) ? setInvalid(false) : setInvalid(true);
    } else {
      setAvatarErr(t("File upload phải < 2MB"));
      setInvalid(true);
    }
  }

  const handleTitle = event => {
    if (event.target.value) {
      setTitleErr('');
    } else {
      setTitleErr(t('Nhập tên công việc!'));
    }
    setTitle(event.target.value);
    event.target.value && !avatarErr && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0
      && Number(salaryFrom) < Number(salaryTo) && Number(ageFrom) < Number(ageTo) ? setInvalid(false) : setInvalid(true);
  }

  const handleCountry = event => {
    setCountryId({ value: event.value, label: event.label, currency: event.currency, visas: event.visas });
    setCurrencyUpdate({ value: event.currency, label: event.currency });
    const visaAllArr = [{ value: 0, label: t("kinhnghiem.khongyeucau") }];
    if (event.visas.length) {
      event.visas.map((item, key) => {
        if (key === 0) {
          setVisaType({ value: item.id, label: item.name, countryId: item.countryId });
        }
        visaAllArr.push({ value: item.id, label: item.name, countryId: item.countryId });
      })
      setVisaTypeList(visaAllArr);
    }
    setNationality({ value: event.value, label: event.label });
    const cityListArr = [];
    cityListAll.map((item, key) => {
      const citieDropdown = item.filter(c => c.countryID == event.value);
      if (citieDropdown.length > 0) {
        citieDropdown.map((itemcity, keycity) => {
          if (keycity === 0) {
            setCityId({ value: itemcity.id, label: itemcity.name });
          }
          cityListArr.push({ value: itemcity.id, label: itemcity.name });
        });
      }
    });
    setCityList(cityListArr);
    if (cityListArr.length == 0) {
      setCityErr(t('Chọn thành phố!'));
      setCityId({ value: 0, label: '' });
    } else {
      setCityErr('');
    }
    title && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityListArr.length > 0
      && Number(salaryFrom) < Number(salaryTo) && Number(ageFrom) < Number(ageTo) ? setInvalid(false) : setInvalid(true);

    let foreignLanguageData = foreignLanguageAll.filter(f => f.countryId == event.value);
    const languageListArr = [];
    foreignLanguageData.map((item, key) => {
      let languageDefine = '';
      if (item.levelCode === "JP") {
        languageDefine = t('Tiếng Nhật');
      }
      if (item.levelCode === "KR") {
        languageDefine = t('Tiếng Hàn');
      }
      if (item.levelCode === "GER") {
        languageDefine = t('Tiếng Đức');
      }
      if (item.levelCode === "EN") {
        languageDefine = t('Tiếng Anh');
      }
      if (item.levelCode === "CN") {
        languageDefine = t('Tiếng Trung');
      }
      if (item.levelCode === "SA") {
        languageDefine = t('Tiếng Ả Rập');
      }
      if (item.levelCode === "RU") {
        languageDefine = t('Tiếng Nga');
      }
      languageListArr.push({ value: item.id, label: item.levelName });
      if (key === 0) {
        setForeignLanguage({ value: item.id, label: languageDefine, levelCode: item.levelCode, levelName: item.levelName });
        setforeignLanguageLevel({ value: item.id, label: item.levelName });
      }
    });
    setForeignLanguageLevelList(languageListArr);
  }

  const handleCity = event => {
    setCityId({ value: event.value, label: event.label });
  }

  const handleWorkLocationAddress = event => {
    setWorkLocationAddress(event.target.value);
  }

  const handleNationality = event => {
    if (event.value >= 0 || event.length > 0) {
      setNationalityErr('');
    } else {
      setNationalityErr('Chọn quốc tịch!');
    }
    setNationality(event);
    title && !avatarErr && (event.value >= 0 || event.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0
      && Number(salaryFrom) < Number(salaryTo) && Number(ageFrom) < Number(ageTo) ? setInvalid(false) : setInvalid(true);
  }

  const handleExperience = event => {
    setExperience({ value: event.value, label: event.label });
  }

  const handleJobType = event => {
    setJobTypeUpdate({ value: event.value, label: event.label });
  }

  const handleWorkingType = event => {
    setWorkingType({ value: event.value, label: event.label });
  }

  const handlePosition = event => {
    setPositionUpdate({ value: event.value, label: event.label });
  }

  const handleIndustry = event => {
    setIndustry({ value: event.value, label: event.label });
    let fieldList = industryList.filter(i => i.value == event.value);
    if (fieldList.length > 0) {
      const fieldArr = [];
      fieldList[0].children.map((item, key) => {
        if (key === 0) {
          setField({ value: item.id, label: item.nameVi });
        }
        fieldArr.push({ value: item.id, label: item.nameVi });
      })
      setFieldsList(fieldArr);
    }
  }

  const handleField = event => {
    setField({ value: event.value, label: event.label });
  }

  const handleTotalWorkerNeeded = event => {
    setTotalWorkerNeeded(event.target.value);
  }

  const handleSalaryFrom = value => {
    if (Number(value) > Number(salaryTo)) {
      setSalaryFromErr('Không được < mức lương đến');
    } else {
      if (!value || !salaryTo) {
        setSalaryFromErr('Nhập mức lương');
        setSalaryToErr('');
      } else {
        setSalaryFromErr('');
        setSalaryToErr('');
      }
    }
    setSalaryFrom(value);
    title && !avatarErr && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0
      && Number(value) < Number(salaryTo) && Number(ageFrom) < Number(ageTo) ? setInvalid(false) : setInvalid(true);
  }

  const handleSalaryTo = value => {
    if (Number(value) < Number(salaryFrom)) {
      setSalaryToErr('Không được < mức lương từ');
    } else {
      if (!value || !salaryFrom) {
        setSalaryToErr('Nhập mức lương');
        setSalaryFromErr('');
      } else {
        setSalaryToErr('');
        setSalaryFromErr('');
      }
    }
    setSalaryTo(value);
    title && !avatarErr && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0
      && Number(salaryFrom) < Number(value) && Number(ageFrom) < Number(ageTo) ? setInvalid(false) : setInvalid(true);
  }

  const handleCurrencyUpdate = event => {
    setCurrencyUpdate({ value: event.value, label: event.label });
  }

  const handleDateNeeded = event => {
    setDateNeeded(event.target.value);
  }

  const handleJobDescription = editorState => {
    let content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (content.indexOf("color: rgb(18,18,18);background-color: rgb(255,255,255);") > -1) {
      content = content.replaceAll("color: rgb(18,18,18);background-color: rgb(255,255,255);", "");
    }
    setJobDescription(content);
    setEditorJobDescription(editorState);
  }

  const handleJobRequired = editorState => {
    let content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (content.indexOf("color: rgb(18,18,18);background-color: rgb(255,255,255);") > -1) {
      content = content.replaceAll("color: rgb(18,18,18);background-color: rgb(255,255,255);", "");
    }
    setJobRequired(content);
    setEditorJobRequired(editorState);
  }

  const handleGender = event => {
    setGender({ value: event.value, label: event.label });
  }

  const handleAgeFrom = event => {
    if (event.target.value > 0) {
      if (Number(event.target.value) > Number(ageTo)) {
        setAgeFromErr('Không được < tuổi đến');
      } else {
        if (!event.target.value || !ageTo) {
          setAgeFromErr('Nhập độ tuổi');
          setAgeToErr('');
        } else {
          setAgeFromErr('');
          setAgeToErr('');
        }
      }
    } else {
      setAgeFromErr('Độ tuổi phải > 0');
    }
    setAgeFrom(event.target.value);
    title && !avatarErr && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0
      && Number(salaryFrom) < Number(salaryTo) && Number(event.target.value) < Number(ageTo) ? setInvalid(false) : setInvalid(true);
  }

  const handleAgeTo = event => {
    if (event.target.value > 0) {
      if (Number(event.target.value) < Number(ageFrom)) {
        setAgeToErr('Không được < tuổi từ');
      } else {
        if (!event.target.value || !ageFrom) {
          setAgeToErr('Nhập độ tuổi');
          setAgeFromErr('');
        } else {
          setAgeToErr('');
          setAgeFromErr('');
        }
      }
    } else {
      setAgeToErr('Độ tuổi phải > 0');
    }
    setAgeTo(event.target.value);
    title && !avatarErr && (nationality.value >= 0 || nationality.length > 0) && (visaType.value >= 0 || visaType.length > 0) && cityId.value > 0
      && Number(salaryFrom) < Number(salaryTo) && Number(ageFrom) < Number(event.target.value) ? setInvalid(false) : setInvalid(true);
  }

  const handleVisaType = event => {
    if (event.value >= 0 || event.length > 0) {
      setVisaTypeErr('');
    } else {
      setVisaTypeErr('Chọn loại visa!');
    }
    setVisaType(event);
    title && !avatarErr && (nationality.value >= 0 || nationality.length > 0) && (event.value >= 0 || event.length > 0) && cityId.value > 0
      && Number(salaryFrom) < Number(salaryTo) && Number(ageFrom) < Number(ageTo) ? setInvalid(false) : setInvalid(true);
  }

  const handleForeignLanguage = event => {
    setForeignLanguage({ value: event.value, label: event.label, levelCode: event.levelCode });
    setforeignLanguageLevel({ value: event.value, label: event.levelName });
    const languageLevelData = [];
    foreignLanguageAll.map((item, key) => {
      if (item.levelCode == event.levelCode) {
        languageLevelData.push({ value: item.id, label: item.levelName });
      }
    });
    setForeignLanguageLevelList(languageLevelData);
  }

  const handleForeignLanguageLevel = event => {
    setforeignLanguageLevel({ value: event.value, label: event.label });
  }

  const handleForeignLanguage2 = event => {
    setForeignLanguage2({ value: event.value, label: event.label, levelCode: event.levelCode });
    setforeignLanguageLevel2({ value: event.value, label: event.levelName });
    const languageLevelData = [];
    foreignLanguageAll.map((item, key) => {
      if (item.levelCode == event.levelCode) {
        languageLevelData.push({ value: item.id, label: item.levelName });
      }
    });
    setForeignLanguageLevelList2(languageLevelData);
  }

  const handleForeignLanguageLevel2 = event => {
    setforeignLanguageLevel2({ value: event.value, label: event.label });
  }

  const handleForeignLanguage3 = event => {
    setForeignLanguage3({ value: event.value, label: event.label, levelCode: event.levelCode });
    setforeignLanguageLevel3({ value: event.value, label: event.levelName });
    const languageLevelData = [];
    foreignLanguageAll.map((item, key) => {
      if (item.levelCode == event.levelCode) {
        languageLevelData.push({ value: item.id, label: item.levelName });
      }
    });
    setForeignLanguageLevelList3(languageLevelData);
  }

  const handleForeignLanguageLevel3 = event => {
    setforeignLanguageLevel3({ value: event.value, label: event.label });
  }

  const handleIsForeignLanguage = () => {
    setIsForeignLanguage(!isForeignLanguage);
  }

  const handleEducationLevel = event => {
    setEducationLevel({ value: event.value, label: event.label });
  }

  const handleCandidateType = event => {
    setCandidateType({ value: event.value, label: event.label });
  }

  const handleChangeBonus = event => {
    setBonus(event.target.checked);
  }

  const handleReviewSalary = event => {
    setReviewSalary({ value: event.value, label: event.label });
  }

  const handleChangeAnTrua = event => {
    settcAnTrua(event.target.checked);
  }

  const handleChangeNhaO = event => {
    settcNhaO(event.target.checked);
  }

  const handleChangeDiLai = event => {
    settcDiLai(event.target.checked);
  }

  const handleChangeNghiViec = event => {
    settcNghiViec(event.target.checked);
  }

  const handleChangeKhac = event => {
    settcKhac(event.target.checked);
  }

  const handleContentKhac = event => {
    setContentKhac(event.target.value);
  }

  const handleChangeHSCV = event => {
    setHSCV(event.target.checked);
  }

  const handleChangeHSBC = event => {
    setHSBC(event.target.checked);
  }

  const handleChangeHSCC = event => {
    setHSTC(event.target.checked);
  }

  const handleChangeHSTCT = event => {
    setHSTCT(event.target.checked);
  }

  const handleChangeHSHC = event => {
    setHSHC(event.target.checked);
  }

  const handleChangeHSBLX = event => {
    setHSBLX(event.target.checked);
  }

  const handleUpdateJob = (jobId) => {
    let visaData = '';
    if (visaType) {
      if (visaType.length > 0) {
        visaType.map((item, key) => {
          if (key == visaType.length - 1) {
            visaData += item.value;
          } else {
            visaData += item.value + ',';
          }
        })
      } else {
        visaData = visaType.value;
      }
    }

    let nationalityData = '';
    if (nationality) {
      if (nationality.length > 0) {
        nationality.map((item, key) => {
          if (key == nationality.length - 1) {
            nationalityData += item.value;
          } else {
            nationalityData += item.value + ',';
          }
        })
      } else {
        nationalityData = nationality.value.toString();
      }
    }

    let job = {
      id: jobId,
      title: title,
      jobType: Number(jobTypeUpdate.value),
      gender: Number(gender.value),
      experienceRequired: Number(experience.value),
      position: positionUpdate.value,
      totalWorkerNeeded: Number(totalWorkerNeeded),
      cityId: Number(cityId.value),
      workLocationAddress: workLocationAddress,
      currency: currencyUpdate.value,
      salaryFrom: Number(salaryFrom),
      salaryTo: Number(salaryTo),
      ageTo: Number(ageTo),
      ageFrom: Number(ageFrom),
      dateNeeded: dateNeeded,
      jobDescription: jobDescription,
      jobRequired: jobRequired,
      visaType: visaData.toString(),
      educationLevel: Number(educationLevel.value),
      foreignLanguages: Number(foreignLanguage.value),
      foreignLanguageLevel: foreignLanguageLevel.value.toString(),
      foreignLanguages2: Number(foreignLanguage2.value),
      foreignLanguageLevel2: foreignLanguageLevel2.value.toString(),
      foreignLanguages3: Number(foreignLanguage3.value),
      foreignLanguageLevel3: foreignLanguageLevel3.value.toString(),
      candidateType: Number(candidateType.value),
      workingType: Number(workingType.value),
      bonus: bonus,
      reviewSalary: Number(reviewSalary.value),
      tcAnTrua: tcAnTrua,
      tcNhaO: tcNhaO,
      tcDiLai: tcDiLai,
      tcNghiViec: tcNghiViec,
      tcKhac: tcKhac,
      tcKhac_Content: contentKhac,
      hscv: hscv,
      hsbc: hsbc,
      hstc: hstc,
      hstct: hstct,
      hshc: hshc,
      hsblx: hsblx,
      catId2: Number(field.value),
      catId: Number(industry.value),
      base64file: base64file,
      quoctich: nationalityData
    };
    updateJob(job).then((res) => {
      if (res.status === 200) {
        setAlertUpdateSuccess(true);
        setAlertUpdateDanger(false);
      } else {
        setAlertUpdateDanger(true);
        setAlertUpdateSuccess(false);
      }
    });
    setModalAdd(!modalAdd);
  }

  const handleShowUpdateJob = (jobId) => {
    getJobsById(jobId).then((res) => {
      res.job.image ? setBanner(res.job.image) : setBanner('');
      res.job.title ? setTitle(res.job.title) : setTitle('');

      if (res.job.jobType >= 0) {
        let jobTypeData = jobTypeList.filter(j => j.value == res.job.jobType);
        if (jobTypeData) {
          setJobTypeUpdate({ value: jobTypeData[0].value, label: jobTypeData[0].label });
        }
      }

      if (res.job.workingType >= 0) {
        let workingTypeData = workingTypeList.filter(w => w.value == res.job.workingType);
        if (workingTypeData.length) {
          setWorkingType({ value: workingTypeData[0].value, label: workingTypeData[0].label });
        }
      }

      if (res.job.gender >= 0) {
        let genderData = genderList.filter(g => g.value == res.job.gender);
        if (genderData) {
          setGender({ value: genderData[0].value, label: genderData[0].label });
        }
      }

      if (res.job.experienceRequired >= 0) {
        let experienceData = experienceRequiredList.filter(e => e.value == res.job.experienceRequired);
        if (experienceData) {
          setExperience({ value: experienceData[0].value, label: experienceData[0].label });
        }
      }

      if (res.job.position >= 0) {
        let positionData = positionList.filter(p => p.value == res.job.position);
        if (positionData) {
          setPositionUpdate({ value: positionData[0].value, label: positionData[0].label });
        }
      }

      if (countryListAll) {
        const cityArr = [];
        countryListAll.map((item, key) => {
          if (key === 0 && !res.job.cityId) {
            setCountryId({ value: item.id, label: item.name });
            let cities = item.cities.filter(c => c.countryID === item.id);
            if (cities.length > 0) {
              cities.map((itemcity, keycity) => {
                if (keycity === 0) {
                  setCityId({ value: itemcity.id, label: itemcity.name });
                }
                cityArr.push({ value: itemcity.id, label: itemcity.name });
              })
              setCityList(cityArr);
            }
          }
          if (res.job.cityId) {
            item.cities.map((city, keycity) => {
              if (city.id == res.job.cityId) {
                setCityId({ value: city.id, label: city.name });
                setCountryId({ value: item.id, label: item.name });
                setCityList(cityArr);
              }
            })
          }
        })
      }
      res.job.workLocationAddress ? setWorkLocationAddress(res.job.workLocationAddress) : setWorkLocationAddress('');
      res.job.totalWorkerNeeded ? setTotalWorkerNeeded(res.job.totalWorkerNeeded) : setTotalWorkerNeeded(2);
      res.job.salaryFrom ? setSalaryFrom(res.job.salaryFrom) : setSalaryFrom(500);
      res.job.salaryTo ? setSalaryTo(res.job.salaryTo) : setSalaryTo(1000);
      res.job.ageTo ? setAgeTo(res.job.ageTo) : setAgeTo(35);
      res.job.ageFrom ? setAgeFrom(res.job.ageFrom) : setAgeFrom(18);
      if (res.job.currency) {
        setCurrencyUpdate({ value: res.job.currency, label: res.job.currency });
      };

      res.job.dateNeeded ? setDateNeeded(res.job.dateNeeded) : setDateNeeded(date);
      res.job.jobDescription ? setJobDescription(res.job.jobDescription) : setJobDescription('');
      if (res.job.jobDescription) {
        const blocksFromHTML = htmlToDraft(res.job.jobDescription);
        const content = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorJobDescription(EditorState.createWithContent(content));
      };

      res.job.jobRequired ? setJobRequired(res.job.jobRequired) : setJobRequired('');
      if (res.job.jobRequired) {
        const blocksFromHTML = htmlToDraft(res.job.jobRequired);
        const content = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorJobRequired(EditorState.createWithContent(content));
      };

      if (res.job.visaType) {
        const visaTypeData = [];
        if (res.job.visaType.length > 1) {
          let visaData = res.job.visaType.split(',');
          visaData.map((item, key) => {
            let data = visaTypeList.filter(v => v.value == item);
            if (data) {
              visaTypeData.push(data[0]);
            }
          })
        } else {
          let data = visaTypeList.filter(v => v.value == res.job.visaType);
          if (data) {
            visaTypeData.push(data[0]);
          }
        }
        setVisaType(visaTypeData);
      }

      if (res.job.quoctich) {
        const nationalityData = [];
        if (res.job.quoctich.length > 1) {
          let nationalityRes = res.job.quoctich.split(',');
          nationalityRes.map((item, key) => {
            let data = nationalityList.filter(c => c.value == item);
            if (data) {
              nationalityData.push(data[0]);
            }
          })
        } else {
          let data = nationalityList.filter(c => c.value == res.job.quoctich);
          if (data) {
            nationalityData.push(data[0]);
          }
        }
        setNationality(nationalityData);
      }

      if (res.job.catId) {
        let industryData = industryList.filter(i => i.value == res.job.catId);
        if (industryData) {
          setIndustry({ value: industryData[0].value, label: industryData[0].label });
          const fieldArr = [];
          industryData[0].children.map((item, key) => {
            if (item.id === res.job.catId2) {
              setField({ value: item.id, label: item.nameVi });
            }
            fieldArr.push({ value: item.id, label: item.nameVi });
          })
          setFieldsList(fieldArr);
        }
      }

      if (res.job.educationLevel >= 0) {
        let educationLevelData = educationLevelList.filter(e => e.value == res.job.educationLevel);
        if (educationLevelData) {
          setEducationLevel({ value: educationLevelData[0].value, label: educationLevelData[0].label });
        }
      }

      if (res.job.foreignLanguages >= 0) {
        let foreignLanguageData = foreignLanguagesList.filter(p => p.value == res.job.foreignLanguages);
        if (foreignLanguageData) {
          setForeignLanguage({ value: foreignLanguageData[0].value, label: foreignLanguageData[0].label });
        }
      }
      if (res.job.foreignLanguageLevel >= 0) {
        let foreignLanguageLevelData = foreignLanguageLevelAll.filter(f => f.value == res.job.foreignLanguageLevel);
        if (foreignLanguageLevelData.length) {
          setforeignLanguageLevel({ value: foreignLanguageLevelData[0].value, label: foreignLanguageLevelData[0].label });
          let languageLevelList = foreignLanguageLevelAll.filter(f => f.levelCode == foreignLanguageLevelData[0].levelCode);
          setForeignLanguageLevelList(languageLevelList);
        }
      }

      if (res.job.foreignLanguages2 >= 0) {
        let foreignLanguageData = foreignLanguagesList.filter(p => p.value == res.job.foreignLanguages2);
        if (foreignLanguageData) {
          setForeignLanguage2({ value: foreignLanguageData[0].value, label: foreignLanguageData[0].label });
        }
      }
      if (res.job.foreignLanguageLevel2 >= 0) {
        let foreignLanguageLevelData = foreignLanguageLevelAll.filter(f => f.value == res.job.foreignLanguageLevel2);
        if (foreignLanguageLevelData.length) {
          setforeignLanguageLevel2({ value: foreignLanguageLevelData[0].value, label: foreignLanguageLevelData[0].label });
          let languageLevelList = foreignLanguageLevelAll.filter(f => f.levelCode == foreignLanguageLevelData[0].levelCode);
          setForeignLanguageLevelList2(languageLevelList);
        }
      }

      if (res.job.foreignLanguages3 >= 0) {
        let foreignLanguageData = foreignLanguagesList.filter(p => p.value == res.job.foreignLanguages3);
        if (foreignLanguageData) {
          setForeignLanguage3({ value: foreignLanguageData[0].value, label: foreignLanguageData[0].label });
        }
      }
      if (res.job.foreignLanguageLevel3 >= 0) {
        let foreignLanguageLevelData = foreignLanguageLevelAll.filter(f => f.value == res.job.foreignLanguageLevel3);
        if (foreignLanguageLevelData.length) {
          setforeignLanguageLevel3({ value: foreignLanguageLevelData[0].value, label: foreignLanguageLevelData[0].label });
          let languageLevelList = foreignLanguageLevelAll.filter(f => f.levelCode == foreignLanguageLevelData[0].levelCode);
          setForeignLanguageLevelList3(languageLevelList);
        }
      }

      if (res.job.candidateType >= 0) {
        let candidateTypeData = candidateTypeList.filter(c => c.value == res.job.candidateType);
        if (candidateTypeData) {
          setCandidateType({ value: candidateTypeData[0].value, label: candidateTypeData[0].label });
        }
      }

      res.job.bonus ? setBonus(res.job.bonus) : setBonus(false);
      if (res.job.reviewSalary >= 0) {
        let reviewSalaryData = reviewSalaryList.filter(r => r.value == res.job.reviewSalary);
        if (reviewSalaryData.length) {
          setReviewSalary({ value: reviewSalaryData[0].value, label: reviewSalaryData[0].label });
        }
      }

      res.job.tcAnTrua ? settcAnTrua(res.job.tcAnTrua) : settcAnTrua(false);
      res.job.tcNhaO ? settcNhaO(res.job.tcNhaO) : settcNhaO(false);
      res.job.tcDiLai ? settcDiLai(res.job.tcDiLai) : settcDiLai(false);
      res.job.tcNghiViec ? settcNghiViec(res.job.tcNghiViec) : settcNghiViec(false);
      res.job.tcKhac ? settcKhac(res.job.tcKhac) : settcKhac(false);
      res.job.tcKhac_Content ? setContentKhac(res.job.tcKhac_Content) : setContentKhac('');

      res.job.hscv ? setHSCV(res.job.hscv) : setHSCV(false);
      res.job.hsbc ? setHSBC(res.job.hsbc) : setHSBC(false);
      res.job.hstc ? setHSTC(res.job.hstc) : setHSTC(false);
      res.job.hstct ? setHSTCT(res.job.hstct) : setHSTCT(false);
      res.job.hshc ? setHSHC(res.job.hshc) : setHSHC(false);
      res.job.hsblx ? setHSBLX(res.job.hsblx) : setHSBLX(false);
    })
    setModalAdd(!modalAdd);
  }

  const handleOnClickWebsite = () => {
    window.open(website);
  }

  const handleOnClickFacebook = () => {
    window.open(facebook);
  }

  const handleOnClickTwitter = () => {
    window.open(twitter);
  }

  const handleOnClickInstagram = () => {
    window.open(instagram);
  }

  const handleOnClickWhatsapp = () => {
    window.open(whatsapp);
  }

  return (
    <React.Fragment>
      {/* RightSideContent */}
      {alertsuccess && <AlertsSuccess message={MESSAGE_VALIDATE.apply_job_success} />}
      {alertlogin && <AlertsError message={MESSAGE_VALIDATE.apply_job_login} modalState={alertlogin} toggleModal={openLogin} />}
      {alertdanger && <AlertsError message={MESSAGE_VALIDATE.apply_job_fail} modalState={alertdanger} toggleModal={openDanger} />}
      {alertJobExpired && <AlertsError message={MESSAGE_VALIDATE.apply_job_expired} modalState={alertJobExpired} toggleModal={openJobExpired} />}
      {checkFile && <AlertCheckFile message={MESSAGE_VALIDATE.apply_job_checkfile} checkfile={checkFileApplyJob} />}
      {/* Alert Update */}
      {alertUpdateSuccess && <AlertsSuccess message={MESSAGE_VALIDATE.update_profile_success} />}
      {alertUpdateDanger && <AlertsError message={MESSAGE_VALIDATE.update_profile_fail} modalState={alertUpdateDanger} toggleModal={openUpdateDanger} />}
      <Col lg={8}>
        {!props ? (<PlaceholderLoaddingJobDescription />) : <Card className="job-detail overflow-hidden">
          <div>
            <img src={banner ? banner : JobDetailImage} alt="" className="img-fluid" />
            <div className="job-details-compnay-profile">
              <img
                src={companyImage ? companyImage : ImageDefault}
                alt=""
                className="img-fluid rounded-3 rounded-3"
                style={{ height: 'auto', width: '100px', objectFit: 'cover' }}
              />
            </div>
          </div>
          <CardBody className="p-4">
            <div>
              <Row>
                <Col md={8}>
                  <h5 className="mb-1">{title}</h5>
                </Col>
                <Col lg={4}>
                  <ul className="list-inline mb-0 text-lg-end mt-3 mt-lg-0">
                    <li className="list-inline-item">
                      <div className="favorite-icon">
                        <Link to="#">
                          <i className="uil uil-heart-alt"></i>
                        </Link>
                      </div>
                    </li>
                    <li className="list-inline-item">
                      <div className="favorite-icon">
                        <Link to="#">
                          <i className="uil uil-setting"></i>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </Col>
              </Row>
            </div>

            <div className="mt-4">
              <Row className="g-2">
                <Col lg={3}>
                  <div className="border rounded-start p-3">
                    <p className="text-muted mb-0 fs-13">{t("managejob.quoctich")}</p>
                    <p className="fw-medium fs-15 mb-0">{nationalityDes}</p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="border p-3">
                    <p className="text-muted fs-13 mb-0">{t("jobdetail.kinhnghiem")}</p>
                    <p className="fw-medium mb-0">{experienceRequired}</p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="border p-3">
                    <p className="text-muted fs-13 mb-0">{t("jobdetail.loaicongviec")}</p>
                    <p className="fw-medium mb-0">{jobType}</p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="border rounded-end p-3">
                    <p className="text-muted fs-13 mb-0">{t("jobdetail.hinhthuclamviec")}</p>
                    <p className="fw-medium mb-0">{workingTypeDes}</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="mt-3">
              <Row className="g-2">
                <Col lg={3}>
                  <div className="border rounded-start p-3">
                    <p className="text-muted mb-0 fs-13">{t("jobdetail.diadiemlamviec")}</p>
                    <p className="fw-medium fs-15 mb-0">{addressCountry ? addressCountry : ''} {workLocationAddress}</p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="border p-3">
                    <p className="text-muted fs-13 mb-0">{t("companydetail.nganhnghe")}</p>
                    <p className="fw-medium mb-0">{industryDes}</p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="border p-3">
                    <p className="text-muted fs-13 mb-0">{t("managejob.mucluong")}</p>
                    <p className="fw-medium mb-0">{numberFormat(salaryFrom) + ' - ' + numberFormat(salaryTo) + ' ' + currency}</p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="border rounded-end p-3">
                    <p className="text-muted fs-13 mb-0">{t("managejob.loaivisa")}</p>
                    <p className="fw-medium mb-0">{visaTypeDes}</p>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="mt-4">
              <h5 className="mb-3">{t("jobdetail.motacongviec")}</h5>
              <div className="job-detail-desc">
                <div dangerouslySetInnerHTML={{ __html: jobDescription }} />
              </div>
            </div>

            <div className="mt-4">
              <h5 className="mb-3">{t("jobdetail.yeucaucongviec")}</h5>
              <div className="job-detail-desc mt-2">
                <div dangerouslySetInnerHTML={{ __html: jobRequired }} />
              </div>
            </div>

            <div className="mt-4 pt-3">
              <ul className="list-inline mb-0">
                <li className="list-inline-item mt-1">{t("jobdetail.chiasecongviec")}:</li>
                <li className="list-inline-item mt-1">
                  <FacebookShareButton
                    url={urlShare}
                    quote={"TopJob360"}
                    hashtag={"#TopJob360"}
                    description={"TopJob360"}
                  >
                    <Link to="" className="btn btn-facebook btn-hover">
                      <i className="uil uil-facebook-f"></i> Facebook
                    </Link>
                  </FacebookShareButton>
                </li>
                <li className="list-inline-item mt-1">
                  <TwitterShareButton
                    url={urlShare}
                    title={"TopJob360"}
                  >
                    <Link to="" className="btn btn-twitter btn-hover" style={{ width: '130.46px' }}>
                      <i className="uil uil-twitter"></i> Twitter
                    </Link>
                  </TwitterShareButton>
                </li>
                <li className="list-inline-item mt-1">
                  <LinkedinShareButton
                    url={urlShare}
                    subject={"TopJob360"}
                    body="TopJob360"
                  >
                    <Link to="" className="btn btn-linkedin btn-hover" style={{ width: '130.46px' }}>
                      <i className="uil uil-linkedin-alt"></i> Linkedin
                    </Link>
                  </LinkedinShareButton>
                </li>
                <li className="list-inline-item mt-1">
                  <LineShareButton
                    url={urlShare}
                    title={"TopJob360"}
                  >
                    <Link to="" className="btn btn-line btn-hover" style={{ width: '130.46px' }}>
                      <i className="uil uil-line"></i> Line
                    </Link>
                  </LineShareButton>
                </li>
              </ul>
            </div>
          </CardBody>
        </Card>}
        {props && <JobVacancyPost data={props.data} />}
      </Col>

      <Col lg={4} className="mt-4 mt-lg-0">
        <div className="side-bar ms-lg-4">
          <Card className="company-profile">
            <CardBody className="p-4">
              <div className="text-center">
                <img src={companyImage} alt="" className="img-fluid rounded-3" />
                <div className="mt-4">
                  <h6 className="fs-17 mb-1">{fullname}</h6>
                </div>
              </div>
              <ul className="list-unstyled mt-4">
                {phone && (
                  <li>
                    <div className="d-flex">
                      <i className="uil uil-phone-volume text-primary fs-4"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Số điện thoại"
                      ></i>
                      <div className="ms-3 mt-1">
                        <p className="text-muted fs-14 mb-2 text-break">{phone}</p>
                      </div>
                    </div>
                  </li>
                )}
                {email && (
                  <li className="mt-3">
                    <div className="d-flex">
                      <i className="uil uil-envelope text-primary fs-4"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Email"
                      ></i>
                      <div className="ms-3 mt-1">
                        <p className="text-muted fs-14  text-break">
                          {email}
                        </p>
                      </div>
                    </div>
                  </li>
                )}
                {address && (
                  <li className="mt-3">
                    <div className="d-flex">
                      <i className="uil uil-map-marker text-primary fs-4"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Địa chỉ"
                      ></i>
                      <div className="ms-3 mt-1 ">
                        <p className="text-muted fs-14 text-break">
                          {address}
                        </p>
                      </div>
                    </div>
                  </li>
                )}

                <li className="mt-3 "></li>
                {website && <li className="list-inline-item mt-1">
                  <div className="d-flex">
                    <Link
                      onClick={handleOnClickWebsite}
                    >
                      <i className="uil uil-globe text-primary fs-4"></i>
                    </Link>
                  </div>
                </li>}
                {facebook && <li className="list-inline-item mt-1">
                  <div className="d-flex">
                    <Link
                      onClick={handleOnClickFacebook}
                    >
                      <i className="uil uil-facebook text-primary fs-4"></i>
                    </Link>
                  </div>
                </li>}
                {twitter && <li className="list-inline-item mt-1">
                  <div className="d-flex">
                    <Link
                      onClick={handleOnClickTwitter}
                    >
                      <i className="uil uil-twitter text-primary fs-4"></i>
                    </Link>
                  </div>
                </li>}
                {instagram && <li className="list-inline-item mt-1">
                  <div className="d-flex">
                    <Link
                      onClick={handleOnClickInstagram}
                    >
                      <i className="uil uil-instagram text-primary fs-4"></i>
                    </Link>
                  </div>
                </li>}
                {whatsapp && <li className="list-inline-item mt-1">
                  <div className="d-flex">
                    <Link
                      onClick={handleOnClickWhatsapp}
                    >
                      <i className="uil uil-whatsapp text-primary fs-4"></i>
                    </Link>
                  </div>
                </li>}
              </ul>
              <div className="mt-4">
                <Link
                  to={routeCompany + props.data.userName}
                  className="btn btn-primary btn-hover w-100 rounded"
                >
                  <i className="mdi mdi-eye"></i> {t("jobdetail.xemtrangcongty")}
                </Link>
              </div>
            </CardBody>
          </Card>
          <Card className="job-overview mt-4">
            <CardBody className="p-4">
              <h6 className="fs-17">{t("jobdetail.thongtinchung")}</h6>
              <ul className="list-unstyled mt-4 mb-0">
                <li>
                  <div className="d-flex mt-4">
                    <i className="uil uil-user icon bg-primary-subtle text-primary" style={{ minWidth: '46px' }}></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">{t("jobdetail.vitricongviec")}</h6>
                      <p className="text-muted mb-0 text-break">{position}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex mt-4">
                    <i className="uil uil-book-open icon bg-primary-subtle text-primary" style={{ minWidth: '46px' }}></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">{t("managejob.chuyenmon")}</h6>
                      <p className="text-muted mb-0 text-break">{fieldsDes}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex mt-4">
                    <i className="uil uil-calendar-alt icon bg-primary-subtle text-primary" style={{ minWidth: '46px' }}></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">{t("managejob.thoigiancan")}</h6>
                      <p className="text-muted mb-0 text-break">{dateNeededDes}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex mt-4">
                    <i className="uil uil-users-alt icon bg-primary-subtle text-primary" style={{ minWidth: '46px' }}></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">{t("managejob.tongsonguoican")}</h6>
                      <p className="text-muted mb-0 text-break">{totalWorkerNeededDes}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex mt-4">
                    <i className="uil uil-user-arrows icon bg-primary-subtle text-primary" style={{ minWidth: '46px' }}></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">{t("managejob.gioitinh")}</h6>
                      <p className="text-muted mb-0 text-break">{genderDes}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex mt-4">
                    <i className="uil uil-user-plus icon bg-primary-subtle text-primary" style={{ minWidth: '46px' }}></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">{t("managejob.dotuoi")}</h6>
                      <p className="text-muted mb-0 text-break">{ageDes}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex mt-4">
                    <i className="uil uil-building icon bg-primary-subtle text-primary" style={{ minWidth: '46px' }}></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">{t("managejob.captotnghiepcuoicung")}</h6>
                      <p className="text-muted mb-0 text-break">{educationLevelDes}</p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex mt-4">
                    <i className="uil uil-receipt-alt icon bg-primary-subtle text-primary" style={{ minWidth: '46px' }}></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">{t("managejob.hinhthuctuyendung")}</h6>
                      <p className="text-muted mb-0 text-break">{candidateTypeDes}</p>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="mt-3">
                {isMe ? <Link
                  to=""
                  onClick={() => handleShowUpdateJob(props.data.id)}
                  className="btn btn-primary btn-hover w-100 mt-2"
                >
                  {t("jobdetail.chinhsua")} <i className="uil uil-edit"></i>
                </Link> : <Link
                  to=""
                  onClick={handleApplyJob}
                  className="btn btn-primary btn-hover w-100 mt-2"
                >
                  {t("jobdetail.ungtuyen")} <i className="uil uil-arrow-right"></i>
                </Link>}
              </div>
            </CardBody>
          </Card>
          <Card className="job-overview mt-4">
            <CardBody className="p-4">
              <h6 className="fs-17">{t("managejob.chedodaingo")}</h6>
              <ul className="list-unstyled mt-4 mb-0">
                <li>
                  <div className="d-flex mt-4">
                    <i className="uil uil-money-insert icon bg-primary-subtle text-primary" style={{ minWidth: '46px' }}></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">{t("managejob.tangluong")}</h6>
                      {bonus && <p className="text-muted mb-0 text-break">{reviewSalaryDes}</p>}
                    </div>
                  </div>
                </li>
                <li>
                  <div className="d-flex mt-4">
                    <i className="uil uil-file-check-alt icon bg-primary-subtle text-primary" style={{ minWidth: '46px' }}></i>
                    <div className="ms-3">
                      <h6 className="fs-14 mb-2">{t("managejob.trocap")}</h6>
                      {bonus && tcAnTruaDes && <span className="badge bg-success-subtle text-success fs-13 mt-1 mx-1">{tcAnTruaDes}</span>}
                      {bonus && tcNhaODes && <span className="badge bg-success-subtle text-success fs-13 mt-1 mx-1">{tcNhaODes}</span>}
                      {bonus && tcDiLaiDes && <span className="badge bg-success-subtle text-success fs-13 mt-1 mx-1">{tcDiLaiDes}</span>}
                      {bonus && tcNghiViecDes && <span className="badge bg-success-subtle text-success fs-13 mt-1 mx-1">{tcNghiViecDes}</span>}
                      {bonus && contentKhacDes && <span className="badge bg-success-subtle text-success fs-13 mt-1 mx-1">{contentKhacDes}</span>}
                    </div>
                  </div>
                </li>
              </ul>
            </CardBody>
          </Card>
          <Card className="job-overview mt-4">
            <CardBody className="p-4">
              <h6 className="fs-17">{t("managejob.hosoyeucau")}</h6>
              {hscvDes && <span className="badge bg-success-subtle text-success fs-13 mt-1 mx-1">{hscvDes}</span>}
              {hsbcDes && <span className="badge bg-success-subtle text-success fs-13 mt-1 mx-1">{hsbcDes}</span>}
              {hstcDes && <span className="badge bg-success-subtle text-success fs-13 mt-1 mx-1">{hstcDes}</span>}
              {hstctDes && <span className="badge bg-success-subtle text-success fs-13 mt-1 mx-1">{hstctDes}</span>}
              {hshcDes && <span className="badge bg-success-subtle text-success fs-13 mt-1 mx-1">{hshcDes}</span>}
              {hsblxDes && <span className="badge bg-success-subtle text-success fs-13 mt-1 mx-1">{hsblxDes}</span>}
            </CardBody>
          </Card>
          <div
            className="modal fade"
            id="applyNow"
            tabIndex="-1"
            aria-labelledby="applyNow"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              {!isMe && <ApplyJobPopup modalState={modal} toggleModal={openModal}
                jobId={props.data.id} dateNeeded={props.data.dateNeeded} message={messageApplyJob} />}
            </div>
          </div>
        </div>
      </Col>

      <Modal isOpen={modalAdd} role="dialog" centered fullscreen
        backdrop="static"
        scrollable={true}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {t("managejob.capnhatcongviec")}
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={openModalAdd}
            ></button>
          </div>
          <ModalBody>
            <div>
              <h5 className="fs-17 fw-semibold mb-3 mb-0">{t("managejob.nhapthongtindontuyen")}</h5>
              <Row>
                <Col lg={8}>
                  <Card className="profile-sidebar me-lg-4">
                    <CardBody className="p-4">
                      <Row>
                        <Col lg={3}>
                          <div className="mb-3">
                            <div className="mb-4 profile-user">
                              <img
                                src={banner ? banner : ImageDefault}
                                className="rounded-3 img-thumbnail profile-img"
                                id="profile-img"
                                alt=""
                              />
                              <div className="p-0 rounded-3 profile-photo-edit">
                                <Input
                                  id="profile-img-file-input"
                                  type="file"
                                  className="profile-img-file-input"
                                  onChange={handleUploadBanner}
                                />
                                <Label
                                  htmlFor="profile-img-file-input"
                                  className="profile-photo-edit avatar-xs"
                                >
                                  <i className="uil uil-edit"></i>
                                </Label>
                              </div>
                            </div>
                          </div>
                          {avatarErr && <label style={{ color: 'red', fontSize: 14 }}>{avatarErr}</label>}
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Row style={{ width: '100%' }}>
                              <Label
                                htmlFor="addressjob"
                                className="form-label"
                              >
                                {t("managejob.diachilamviec")}
                              </Label>
                              <div style={{ width: '20%' }}>
                                <Select
                                  isMulti={false}
                                  value={countryId}
                                  placeholder={'Quốc gia...'}
                                  onChange={handleCountry}
                                  styles={customReactSelectStyles}
                                  options={countryList}
                                  className="react-select-container"
                                  classNamePrefix="react-select"

                                />
                              </div>
                              <div style={{ width: '30%', marginLeft: -23 }}>
                                <Select
                                  isMulti={false}
                                  value={cityId}
                                  placeholder={'Thành phố...'}
                                  onChange={handleCity}
                                  styles={cityErr ? customSelectValidateStyles : customReactSelectStyles}
                                  options={cityList}
                                  className="react-select-container"
                                  classNamePrefix="react-select"

                                />
                              </div>
                              <Input
                                style={{ width: '50%', marginLeft: -11 }}
                                type="text"
                                className="form-control"
                                id="workLocationAddress"
                                onChange={handleWorkLocationAddress}
                                value={workLocationAddress}
                                placeholder={t("managejob.diachi_placeholder")}
                              />
                              {cityErr && <label style={{ color: 'red', fontSize: 14 }}>{cityErr}</label>}
                            </Row>
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <label htmlFor="title" className="form-label">
                              {t("managejob.tencongviec")}
                            </label>
                            <label style={{ paddingLeft: 2, color: 'red', fontSize: 14 }}>*</label>
                            <div className="position-relative">
                              <Input
                                type="text"
                                className="form-control"
                                id="title"
                                onChange={handleTitle}
                                value={title}
                                placeholder={t("managejob.tencongviec_placeholder")}
                                style={{ paddingLeft: '40px', borderColor: titleErr ? 'red' : '#eff0f2' }}
                              />
                              <Icon style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: '#02AF74', fontSize: '20px' }}
                                icon="material-symbols:business-center-outline" />
                            </div>
                            {titleErr && <label style={{ color: 'red', fontSize: 14 }}>{titleErr}</label>}
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <label htmlFor="nationality" className="form-label">
                              {t("managejob.quoctich")}
                            </label>

                            <Select
                              isMulti={true}
                              value={nationality}
                              id="choices-nationality"
                              onChange={handleNationality}
                              options={nationalityList}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  height: 'auto',
                                  fontWeight: 500,
                                  borderColor: nationalityErr ? 'red' : '#eff0f2'
                                }),
                              }}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                            {nationalityErr && <label style={{ color: 'red', fontSize: 14 }}>{nationalityErr}</label>}
                          </div>
                        </Col>
                        {experience && <Col lg={3}>
                          <div className="mb-3">
                            <Label htmlFor="experience" className="form-label">
                              {t("managejob.kinhnghiem")}
                            </Label>
                            <Select
                              isMulti={false}
                              options={experienceRequiredList}
                              value={experience}
                              id="choices-experience"
                              onChange={handleExperience}
                              styles={customReactSelectStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>}
                        {jobType && <Col lg={3}>
                          <div className="mb-3">
                            <label htmlFor="jobType" className="form-label">
                              {t("managejob.loaicongviec")}
                            </label>

                            <Select
                              isMulti={false}
                              options={jobTypeList}
                              value={jobTypeUpdate}
                              id="choices-jobType"
                              onChange={handleJobType}
                              styles={customReactSelectStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>}
                        <Col lg={3}>
                          <div className="mb-3">
                            <label htmlFor="workingType" className="form-label">
                              Hình thức làm việc
                            </label>

                            <Select
                              isMulti={false}
                              options={workingTypeList}
                              value={workingType}
                              id="choices-workingType"
                              onChange={handleWorkingType}
                              styles={customReactSelectStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div className="mb-3">
                            <label htmlFor="position" className="form-label">
                              {t("managejob.vitri")}
                            </label>

                            <Select
                              isMulti={false}
                              options={positionList}
                              value={positionUpdate}
                              id="choices-position"
                              onChange={handlePosition}
                              styles={customReactSelectStyles}
                              menuPosition={'fixed'}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <label htmlFor="industry" className="form-label">
                              {t("managejob.nganhnghe")}
                            </label>

                            <Select
                              isMulti={false}
                              value={industry}
                              id="choices-industry"
                              onChange={handleIndustry}
                              placeholder={'Chọn ngành nghề...'}
                              styles={customReactSelectStyles}
                              options={industryList}
                              menuPosition={'fixed'}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>
                        <Col lg={5}>
                          <div className="mb-3">
                            <label htmlFor="position" className="form-label">
                              {t("managejob.chuyenmon")}
                            </label>

                            <Select
                              isMulti={false}
                              value={field}
                              id="choices-field"
                              onChange={handleField}
                              styles={customReactSelectStyles}
                              options={fieldsList}
                              menuPosition={'fixed'}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Row style={{ width: '100%', marginLeft: -1 }}>
                              <Label htmlFor="salary" className="form-label" style={{ marginLeft: -13 }} >
                                {t("managejob.mucluong")}
                              </Label>
                              <CurrencyInput
                                groupSeparator=','
                                decimalSeparator='.'
                                style={{ width: '35%' }}
                                id="salaryFrom"
                                allowDecimals={false}
                                className="form-control"
                                value={salaryFrom}
                                onValueChange={handleSalaryFrom}
                                step={10}
                              />
                              <label style={{ width: '20px', marginTop: 10, marginLeft: -8 }}>~</label>
                              <CurrencyInput
                                groupSeparator=','
                                decimalSeparator='.'
                                style={{ width: '35%' }}
                                id="salaryTo"
                                allowDecimals={false}
                                className="form-control"
                                value={salaryTo}
                                onValueChange={handleSalaryTo}
                                step={10}
                              />

                              <Select
                                isMulti={false}
                                options={currencyList}
                                value={currencyUpdate}
                                id="choices-currency"
                                onChange={handleCurrencyUpdate}
                                className="react-select-container w-25 p-0"
                                styles={customReactSelectStyles}
                                menuPosition={'fixed'}
                                classNamePrefix="react-select"
                              />
                            </Row>
                            {salaryFromErr && <label style={{ color: 'red', fontSize: 14 }}>{salaryFromErr}</label>}
                            {salaryToErr && <label style={{ color: 'red', fontSize: 14 }}>{salaryToErr}</label>}
                          </div>
                        </Col>

                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="jobDescription" className="form-label">
                              {t("managejob.motacongviec")}
                            </Label>
                            <Editor
                              wrapperStyle={{
                                borderRadius: '4px',
                                border: '1px solid rgba(0, 0, 0, 0.23)',
                              }}
                              toolbarStyle={{
                                border: 'none',
                                display: 'inline-brock',
                              }}
                              editorStyle={{
                                height: 'min-content',
                                maxHeight: '200px',
                                minHeight: '150px',
                                overflow: 'auto',
                                paddingLeft: '15px',
                              }}
                              editorState={editorJobDescription}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              // localization={{
                              //   locale: 'ja',
                              // }}
                              onEditorStateChange={handleJobDescription}
                            />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="jobRequired" className="form-label">
                              {t("managejob.yeucautuyendung")}
                            </Label>
                            <Editor
                              wrapperStyle={{
                                borderRadius: '4px',
                                border: '1px solid rgba(0, 0, 0, 0.23)',
                              }}
                              toolbarStyle={{
                                border: 'none',
                                display: 'inline-brock',
                              }}
                              editorStyle={{
                                height: 'min-content',
                                maxHeight: '200px',
                                minHeight: '150px',
                                overflow: 'auto',
                                paddingLeft: '15px',
                              }}
                              editorState={editorJobRequired}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              // localization={{
                              //   locale: 'ja',
                              // }}
                              onEditorStateChange={handleJobRequired}
                            />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={4}>
                  <Card className="profile-content-page mt-4 mt-lg-0">
                    <CardBody className="p-4">
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="dateNeeded"
                              className="form-label"
                            >
                              {t("managejob.thoigiancan")}
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="dateNeeded"
                              onChange={handleDateNeeded}
                              value={dateNeeded}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="totalWorkerNeeded" className="form-label">
                              {t("managejob.tongsonguoican")}
                            </Label>
                            <div className="position-relative">
                              <Input
                                type="number"
                                className="form-control"
                                id="totalWorkerNeeded"
                                onChange={handleTotalWorkerNeeded}
                                value={totalWorkerNeeded}
                                placeholder="Nhập tổng số người cần làm việc"
                                style={{ paddingLeft: '40px' }}
                              />
                              <Icon style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', color: '#02AF74', fontSize: '20px' }}
                                icon="mdi:account-multiple-outline" />
                            </div>
                          </div>
                        </Col>
                        {gender && <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="gender" className="form-label">
                              {t("managejob.gioitinh")}
                            </Label>

                            <Select
                              isMulti={false}
                              options={genderList}
                              value={gender}
                              id="choices-gender"
                              onChange={handleGender}
                              styles={customReactSelectStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>}
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="ageFrom" className="form-label">
                              {t("Độ tuổi")}
                            </Label>
                            <Row style={{ width: '100%', marginLeft: -1 }}>
                              <Input
                                type="number"
                                className="form-control"
                                id="ageFrom"
                                onChange={handleAgeFrom}
                                value={ageFrom}
                                style={{ width: '48%' }}
                              />
                              <label style={{ width: '20px', marginTop: 10, marginLeft: -8 }}>~</label>
                              <Input
                                type="number"
                                className="form-control"
                                id="ageTo"
                                onChange={handleAgeTo}
                                value={ageTo}
                                style={{ width: '48%' }}
                              />
                            </Row>
                            {ageFromErr && <label style={{ color: 'red', fontSize: 14 }}>{ageFromErr}</label>}
                            {ageToErr && <label style={{ color: 'red', fontSize: 14 }}>{ageToErr}</label>}
                          </div>
                        </Col>
                        {visaType && <Col lg={12}>
                          <div className="mb-3">
                            <Label htmlFor="visatype" className="form-label">
                              {t("managejob.loaivisa")}
                            </Label>

                            <Select
                              isMulti={true}
                              value={visaType}
                              id="choices-visatype"
                              onChange={handleVisaType}
                              options={visaTypeList}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: visaTypeErr ? 'red' : '#eff0f2',
                                  height: 'auto',
                                  fontWeight: 500
                                }),
                              }}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                            {visaTypeErr && <label style={{ color: 'red', fontSize: 14 }}>{visaTypeErr}</label>}
                          </div>
                        </Col>}
                        {foreignLanguage && <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="foreignLanguage" className="form-label">
                              {t("managejob.loaingoaingu")}
                            </Label>

                            <Select
                              isMulti={false}
                              options={foreignLanguagesList}
                              value={foreignLanguage}
                              id="choices-foreignLanguage"
                              onChange={handleForeignLanguage}
                              styles={customReactSelectStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>}
                        {foreignLanguageLevel && <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="foreignLanguageLevel" className="form-label">
                              {t("managejob.trinhdo")}
                            </Label>
                            <Select
                              isMulti={false}
                              options={foreignLanguageLevelList}
                              value={foreignLanguageLevel}
                              id="foreignLanguageLevel"
                              onChange={handleForeignLanguageLevel}
                              styles={customReactSelectStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>}
                        <Col lg={12}>
                          <div className="mb-3">
                            <button className="text-primary" style={{ border: 'none', background: 'none' }} onClick={() => handleIsForeignLanguage()}>
                              <em>{t("managejob.themngoaingu")}</em>
                            </button>
                          </div>
                        </Col>
                        {isForeignLanguage && <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="foreignLanguage2" className="form-label">
                              {t("managejob.loaingoaingu")}
                            </Label>

                            <Select
                              isMulti={false}
                              options={foreignLanguagesList2}
                              value={foreignLanguage2}
                              id="choices-foreignLanguage2"
                              onChange={handleForeignLanguage2}
                              styles={customReactSelectStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>}
                        {isForeignLanguage && <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="foreignLanguageLevel2" className="form-label">
                              {t("managejob.trinhdo")}
                            </Label>
                            <Select
                              isMulti={false}
                              options={foreignLanguageLevelList2}
                              value={foreignLanguageLevel2}
                              id="foreignLanguageLevel2"
                              onChange={handleForeignLanguageLevel2}
                              styles={customReactSelectStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>}
                        {isForeignLanguage && <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="foreignLanguage3" className="form-label">
                              {t("managejob.loaingoaingu")}
                            </Label>

                            <Select
                              isMulti={false}
                              options={foreignLanguagesList3}
                              value={foreignLanguage3}
                              id="choices-foreignLanguage3"
                              onChange={handleForeignLanguage3}
                              styles={customReactSelectStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>}
                        {isForeignLanguage && <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="foreignLanguageLevel3" className="form-label">
                              {t("managejob.trinhdo")}
                            </Label>
                            <Select
                              isMulti={false}
                              options={foreignLanguageLevelList3}
                              value={foreignLanguageLevel3}
                              id="foreignLanguageLevel3"
                              onChange={handleForeignLanguageLevel3}
                              styles={customReactSelectStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>}
                        {educationLevel && <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="educationLevel" className="form-label">
                              {t("managejob.captotnghiepcuoicung")}
                            </Label>

                            <Select
                              isMulti={false}
                              options={educationLevelList}
                              value={educationLevel}
                              id="choices-educationLevel"
                              onChange={handleEducationLevel}
                              styles={customReactSelectStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>}
                        {candidateType && <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="candidateType" className="form-label">
                              {t("managejob.hinhthuctuyendung")}
                            </Label>

                            <Select
                              isMulti={false}
                              options={candidateTypeList}
                              value={candidateType}
                              id="choices-candidateType"
                              onChange={handleCandidateType}
                              styles={customReactSelectStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>}
                        <Col lg={12}>
                          <div className="mb-3">
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="bonus"
                                onChange={handleChangeBonus}
                                checked={bonus}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="bonus"
                                style={{ fontWeight: 'bold' }}
                              >
                                {t("managejob.chedodaingo")}
                              </label>
                            </div>
                          </div>
                        </Col>
                        {bonus && reviewSalary && <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="reviewSalary" className="form-label" style={{ fontWeight: 'bold' }}>
                              {t("managejob.tangluong")}
                            </Label>
                            <Select
                              isMulti={false}
                              options={reviewSalaryList}
                              value={reviewSalary}
                              id="choices-reviewSalary"
                              onChange={handleReviewSalary}
                              styles={customReactSelectStyles}
                              className="react-select-container"
                              classNamePrefix="react-select"
                            />
                          </div>
                        </Col>}
                        {bonus && <Col lg={12}>
                          <div>
                            <Label htmlFor="phucap" className="form-label" style={{ fontWeight: 'bold' }}>
                              {t("managejob.trocap")}
                            </Label>
                          </div>
                        </Col>}
                        {bonus && <Col lg={3}>
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="tcAnTrua"
                              onChange={handleChangeAnTrua}
                              checked={tcAnTrua}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="tcAnTrua"
                            >
                              {t("managejob.antrua")}
                            </label>
                          </div>
                        </Col>}
                        {bonus && <Col lg={3}>
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="tcNhaO"
                              onChange={handleChangeNhaO}
                              checked={tcNhaO}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="tcNhaO"
                            >
                              {t("managejob.nhao")}
                            </label>
                          </div>
                        </Col>}
                        {bonus && <Col lg={3}>
                          <div className="form-check">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="tcDiLai"
                              onChange={handleChangeDiLai}
                              checked={tcDiLai}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="tcDiLai"
                            >
                              {t("managejob.dilai")}
                            </label>
                          </div>
                        </Col>}
                        {bonus && <Col lg={3}>
                          <div className="mb-3">
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="tcNghiViec"
                                onChange={handleChangeNghiViec}
                                checked={tcNghiViec}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="tcNghiViec"
                              >
                                {t("managejob.nghiviec")}
                              </label>
                            </div>
                          </div>
                        </Col>}
                        {bonus && <Col lg={3}>
                          <div className="mb-3">
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="tcKhac"
                                onChange={handleChangeKhac}
                                checked={tcKhac}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="tcKhac"
                              >
                                {t("managejob.khac")}
                              </label>
                            </div>
                          </div>
                        </Col>}
                        {tcKhac && <Col lg={8}>
                          <div className="mb-3">
                            <textarea
                              type="text"
                              className="form-control"
                              id="contentKhac"
                              onChange={handleContentKhac}
                              value={contentKhac}
                            >
                            </textarea>
                          </div>
                        </Col>}
                        <Col lg={12}>
                          <div>
                            <Label htmlFor="hosoyeucau" className="form-label" style={{ fontWeight: 'bold' }}>
                              {t("managejob.hosoyeucau")}
                            </Label>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="hscv"
                                onChange={handleChangeHSCV}
                                checked={hscv}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="hscv"
                              >
                                {t("managejob.cv")}
                              </label>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="hsbc"
                                onChange={handleChangeHSBC}
                                checked={hsbc}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="hsbc"
                              >
                                {t("managejob.bangcap")}
                              </label>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="hstc"
                                onChange={handleChangeHSCC}
                                checked={hstc}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="hstc"
                              >
                                {t("managejob.chungchi")}
                              </label>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="hstct"
                                onChange={handleChangeHSTCT}
                                checked={hstct}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="hstct"
                              >
                                {t("managejob.thecutru")}
                              </label>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="hshc"
                                onChange={handleChangeHSHC}
                                checked={hshc}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="hshc"
                              >
                                {t("managejob.hochieu")}
                              </label>
                            </div>
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="mb-3">
                            <div className="form-check">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="hsblx"
                                onChange={handleChangeHSBLX}
                                checked={hsblx}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="hsblx"
                              >
                                {t("managejob.banglaixe")}
                              </label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              onClick={() => handleUpdateJob(props.data.id)}
              disabled={invalid}
            >
              <i className="uil uil-save"></i> {t("managejob.capnhat")}
            </button>
          </ModalFooter>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default JobDetailsDescription;
