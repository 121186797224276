import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Input,
  NavItem,
  CardBody,
  Label
} from "reactstrap";

import classnames from "classnames";

import { updateCompany } from "../../../api/apiCompany";
import { checkPhoneNumberFormat } from "../../../common/checkValidate/checkPhoneNumber"
import Alerts from "../../ExtraPages/Components/Alerts";
import AlertsSuccess from "../../ExtraPages/Components/AlertsSuccess";
import Spinners from "../../ExtraPages/Components/Spinners";
import { MESSAGE_VALIDATE } from "../../../common/constants/Messages";
import { useTranslation } from "react-i18next";
import { getAccountInfo } from "../../../api/apiAccount";
import { useNavigate } from "react-router-dom";


const CompanyContent = () => {
  const [activeTab, setActiveTab] = useState("1");

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const didMount = useRef(false);

  const navigate = useNavigate();

  const { t } = useTranslation("translation");

  const countryList = [
    { value: "1", label: t("quocgia.vietnam") },
    { value: "2", label: t("quocgia.nhatban") },
    { value: "3", label: t("Anh") },
    { value: "4", label: t("Pháp") },
    { value: "5", label: t("Đức") },
  ];

  const vocativeList = [
    { value: "Mr.", label: "Mr" },
    { value: "Ms.", label: "Ms" },
  ];

  let [fullname, setFullName] = useState('');
  let [industry, setIndustry] = useState('');
  let [phone, setPhone] = useState('');
  let [address, setAddress] = useState('');
  let [countryId, setCountryId] = useState(1);
  let [vocative, setVocative] = useState('Mr.');
  let [contactperson, setContactPerson] = useState('');
  let [website, setWebsite] = useState('');

  let [alertsuccess, setAlertSuccess] = useState(false);
  let [alertdanger, setAlertDanger] = useState(false);
  let [loading, setLoading] = useState(false);
  let [invalid, setInvalid] = useState(true);
  let [invalidEdit, setInvalidEdit] = useState(false);
  let [disableProfile, setDisableProfile] = useState(true);
  let [loadData, setLoadData] = useState(false);

  //check validate
  let [fullnameErr, setFullNameErr] = useState('');
  let [phoneErr, setPhoneErr] = useState('');

  useEffect(() => {
    getAccountInfo().then((res) => {
      if (res) {
        res.fullName ? setFullName(res.fullName) : setFullName('');
        res.address ? setAddress(res.address) : setAddress('');
        res.phone ? setPhone(res.phone) : setPhone('');
        res.cityId ? setCountryId(res.cityId) : setCountryId('');
        res.candidateData.industry ? setIndustry(res.candidateData.industry) : setIndustry('');
        if (res.candidateData.contactPersonName) {
          let contact = res.candidateData.contactPersonName.split('.');
          setVocative(contact[0] + '.');
          setContactPerson(contact[1]);
        }
        res.website ? setWebsite(res.website) : setWebsite('');
        setLoadData(true);
      } else {
        setLoadData(true);
      }
    })
  }, []);

  const handleFullName = event => {
    setFullName(event.target.value);
  }

  const handleIndustry = event => {
    setIndustry(event.target.value);
  }

  const handlePhone = event => {
    setPhone(event.target.value);
  }

  const handleAddress = event => {
    setAddress(event.target.value);
  }

  const handleCountry = event => {
    setCountryId(event.target.value);
  }

  const handleVocative = event => {
    setVocative(event.target.value);
  }

  const handleContactPerson = event => {
    setContactPerson(event.target.value);
  }

  const handleWebsite = event => {
    setWebsite(event.target.value);
  }

  const background_color = disableProfile ? '#C0C0C0' : '#FFFFFF';

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('fullname');
  }, [fullname]);

  useEffect(() => {
    if (!didMount.current) {
      return () => {
        didMount.current = true;
      };
    }
    checkValidate('phone');
  }, [phone]);

  const checkValidate = (value) => {
    let checkFullName = true;
    let checkPhone = true;
    if (value === 'fullname') {
      if (!fullname) {
        setFullNameErr(t('Vui lòng nhập họ tên!'));
        checkFullName = true;
      } else {
        setFullNameErr('');
        checkFullName = false;
      }
      !checkPhoneNumberFormat(phone) ? checkPhone = true : checkPhone = false;
    }
    if (value === 'phone') {
      if (!checkPhoneNumberFormat(phone)) {
        setPhoneErr(t('Định dạng SĐT chưa chính xác!'));
        checkPhone = true;
      } else {
        setPhoneErr('');
        checkPhone = false;
      }
      !fullname ? checkFullName = true : checkFullName = false;
    }
    (checkFullName || checkPhone) ? setInvalid(true) : setInvalid(false);
  }

  const handleEdit = () => {
    setDisableProfile(false);
    setInvalidEdit(true);
  }

  const handleUpdateProfile = () => {
    let candidateInfo = {
      industry: industry,
      contactPersonName: vocative + '' + contactperson
    };
    let companyInfo = {
      fullName: fullname,
      phone: phone,
      address: address,
      cityId: Number(countryId),
      website: website,
      candidateInfo
    }
    setAlertDanger(false);
    setAlertSuccess(false);
    setLoading(true);
    updateCompany(companyInfo).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        setAlertSuccess(true);
        setAlertDanger(false);
      } else {
        setLoading(false);
        setAlertDanger(true);
        setAlertSuccess(false);
      }
    }, (error) => {
      setLoading(false);
      setAlertDanger(true);
      setAlertSuccess(false);
    });
  }

  const handleView = () => {
    navigate("/companydetails");
  };

  return (
    <React.Fragment>
      {loadData && <Col lg={12}>
        <Card className="profile-content-page mt-4 mt-lg-0">
          {loading && <Spinners />}
          <Nav
            className="profile-content-nav nav-pills border-bottom mb-4"
            id="pills-tab"
            role="tablist"
          >
            <NavItem role="presentation">
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  tabChange("1");
                }}
                type="button"
              >
                {t("Thông tin chung")}
              </NavLink>
            </NavItem>
          </Nav>

          <CardBody className="p-4">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div>
                  <h5 className="fs-17 fw-semibold mb-3 mb-0">{t("Tài khoản của nhà tuyển dụng")}</h5>
                  <Row>
                    <Col lg={3}>
                      <div className="mb-3">
                        <label htmlFor="firstName" className="form-label">
                          {t("Tên công ty")}
                        </label>
                        <label style={{ paddingLeft: 2, color: 'red', fontSize: 14 }}>*</label>
                        <Input
                          style={{ backgroundColor: background_color }}
                          type="text"
                          className="form-control"
                          id="firstName"
                          onChange={handleFullName}
                          value={fullname}
                          disabled={disableProfile}
                        />
                        {fullnameErr && <label style={{ color: 'red', fontSize: 14 }}>{fullnameErr}</label>}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <label htmlFor="industry" className="form-label">
                          {t("Ngành nghề")}
                        </label>
                        <Input
                          style={{ backgroundColor: background_color }}
                          type="text"
                          className="form-control"
                          id="industry"
                          onChange={handleIndustry}
                          value={industry}
                          disabled={disableProfile}
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label htmlFor="address" className="form-label">
                          {t("Địa chỉ")}
                        </Label>
                        <Input
                          style={{ backgroundColor: background_color }}
                          type="text"
                          className="form-control"
                          id="address"
                          onChange={handleAddress}
                          value={address}
                          disabled={disableProfile}
                        />
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <label
                          htmlFor="choices-single-categories"
                          className="form-label"
                        >
                          {t("Quốc gia")}
                        </label>
                        <label style={{ paddingLeft: 2, color: 'red', fontSize: 14 }}>*</label>
                        <select
                          style={{ backgroundColor: background_color }}
                          className="form-select"
                          data-trigger
                          name="choices-country"
                          id="choices-country"
                          aria-label="Default select country"
                          onChange={handleCountry}
                          defaultValue={countryId}
                          disabled={disableProfile}
                        >
                          {
                            countryList.map((countryDetail, key) => (
                              <option key={countryDetail.value} value={countryDetail.value}>{countryDetail.label}</option>
                            ))
                          }
                        </select>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label htmlFor="phone" className="form-label">
                          {t("Điện thoại")}
                        </Label>
                        <label style={{ paddingLeft: 2, color: 'red', fontSize: 14 }}>*</label>
                        <Input
                          style={{ backgroundColor: background_color }}
                          type="text"
                          className="form-control"
                          id="phone"
                          onChange={handlePhone}
                          value={phone}
                          disabled={disableProfile}
                        />
                        {phoneErr && <label style={{ color: 'red', fontSize: 14 }}>{phoneErr}</label>}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <Row style={{ width: '100%', marginLeft: -1 }}>
                        <Label htmlFor="vocative" className="form-label" style={{ marginLeft: -13 }} >
                          {t("Liên hệ")}
                        </Label>
                        <select
                          style={{ backgroundColor: background_color, width: '30%' }}
                          className="form-select"
                          data-trigger
                          name="choices-vocative"
                          id="choices-vocative"
                          aria-label="Default select vocative"
                          onChange={handleVocative}
                          defaultValue={vocative}
                          disabled={disableProfile}
                        >
                          {
                            vocativeList.map((vocativeDetail, key) => (
                              <option key={vocativeDetail.value} value={vocativeDetail.value}>{vocativeDetail.label}</option>
                            ))
                          }
                        </select>
                        <Input
                          style={{ backgroundColor: background_color, width: '70%' }}
                          type="text"
                          className="form-control"
                          id="contactperson"
                          onChange={handleContactPerson}
                          value={contactperson}
                          disabled={disableProfile}
                        />
                      </Row>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-3">
                        <Label htmlFor="website" className="form-label">
                          {t("Website")}
                        </Label>
                        <Input
                          style={{ backgroundColor: background_color }}
                          type="text"
                          className="form-control"
                          id="website"
                          onChange={handleWebsite}
                          value={website}
                          disabled={disableProfile}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="mt-4 text-end">
                  <button
                    className="btn btn-danger"
                    onClick={handleEdit}
                    disabled={invalidEdit}
                  >
                    {t("Chỉnh sửa")}
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={handleUpdateProfile}
                    disabled={invalid}
                  >
                    {t("Cập nhật")}
                  </button>
                  <button
                    className="btn btn-detail"
                    onClick={handleView}
                    disabled={invalid}
                  >
                    {t("Chi tiết")}
                  </button>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
        {alertsuccess && <AlertsSuccess message={MESSAGE_VALIDATE.update_profile_success} />}
        {alertdanger && <Alerts message={MESSAGE_VALIDATE.update_profile_false} />}
      </Col>}
    </React.Fragment >
  );
};

export default CompanyContent;
